import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { portalServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { JobStatusBadge } from "./JobStatusBadge";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { displayColumn } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestChecklist } from "./ServiceEntryTestChecklist";
import { ServiceEntrySignature } from "./ServiceEntrySignature";
import { ChecklistRadio } from "./ChecklistRadio";
import { TableEmptyRow } from "../shared/DataTable";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import "./ServiceProfilePage.css";


const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const testMode = refs.inventory.testMode;
const jobStatuses = refs.inventory.jobStatus;

export function RegularEntryPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceGeneratorId, setServiceGeneratorId] = useState(null);
  const [serviceHistoryId, setServiceHistoryId] = useState(null);     // null for new entries

  const [isFormReadonly, setIsFormReadonly] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoadingEntry, setIsLoadingEntry] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [servicedDatetime, setServicedDatetime] = useState(new Date());
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [jobStartTime, setJobStartTime] = useState(null);
  const [jobEndTime, setJobEndTime] = useState(null);

  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [stock, setStock] = useState(null);
  const [generatorSerial, setGeneratorSerial] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("Hi there!");

  const [jobStatus, setJobStatus] = useState(jobStatuses.draft);
  const [testedBy, setTestedBy] = useState({});
  const [checkedBy, setCheckedBy] = useState({});

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region States; Generator Status fields
  const [runHours, setRunHours] = useState(null);
  const [oilPressure, setOilPressure] = useState(null);
  const [waterTemperature, setWaterTemperature] = useState(null);
  const [engineRPM, setEngineRPM] = useState(null);
  const [dcOutputVoltage, setDcOutputVoltage] = useState(null);

  const [checkEngineOil, setCheckEngineOil] = useState(null);
  const [checkAirCleanerFilter, setCheckAirCleanerFilter] = useState(null);
  const [checkOilFilter, setCheckOilFilter] = useState(null);
  const [checkFuelFilter, setCheckFuelFilter] = useState(null);
  const [checkWaterRadiatorCap, setCheckWaterRadiatorCap] = useState(null);
  const [checkFanBeltBlade, setCheckFanBeltBlade] = useState(null);
  const [checkBatteries, setCheckBatteries] = useState(null);
  const [checkFuelHosesClamps, setCheckFuelHosesClamps] = useState(null);

  const [problemCause, setProblemCause] = useState(null);
  const [serviceActions, setServiceActions] = useState(null);

  const [aftRunHours, setAftRunHours] = useState(null);
  const [aftTestingPeriod, setAftTestingPeriod] = useState(null);
  const [aftTestingMode, setAftTestingMode] = useState(null);
  const [aftOilPressure, setAftOilPressure] = useState(null);
  const [aftWaterTemperature, setAftWaterTemperature] = useState(null);
  const [aftEngineRPM, setAftEngineRPM] = useState(null);
  const [aftDCVoltage, setAftDCVoltage] = useState(null);
  const [aftFrequency, setAftFrequency] = useState(null);
  const [aftKWHr, setAftKWHr] = useState(null);
  //#endregion

  //#region States; Test Results and Checklist fields
  const [l1n, setL1N] = useState(null);
  const [l2n, setL2N] = useState(null);
  const [l3n, setL3N] = useState(null);

  const [testChecklistId, setTestChecklistId] = useState(null);
  const [checkEngineOilLevel, setCheckEngineOilLevel] = useState(null);
  const [checkCoolantLevel, setCheckCoolantLevel] = useState(null);
  const [checkDriveBeltTension, setCheckDriveBeltTension] = useState(null);
  const [checkBatteriesCondition, setCheckBatteriesCondition] = useState(null);
  const [checkSensors, setCheckSensors] = useState(null);
  const [checkTightenHoldingDownBolts, setCheckTightenHoldingDownBolts] = useState(null);
  const [checkWiringConnection, setCheckWiringConnection] = useState(null);
  const [checkSwitchAndCircuitBreaker, setCheckSwitchAndCircuitBreaker] = useState(null);
  const [checkOilCoolantFuelLeaks, setCheckOilCoolantFuelLeaks] = useState(null);
  const [checkHosesPipesClamps, setCheckHosesPipesClamps] = useState(null);
  const [checkOilDrainPlug, setCheckOilDrainPlug] = useState(null);

  const [failureParts, setFailureParts] = useState([]);
  const [remarks, setRemarks] = useState("");
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.jobHistories.url);
    }
    //let _customerId = state['serviceProfileId'];
    let _serviceProfileId = state['serviceProfileId'];
    let _serviceGeneratorId = state['serviceGeneratorId'];
    let _serviceHistoryId = state['serviceHistoryId'];
    if (!_serviceProfileId || !_serviceGeneratorId || !_serviceHistoryId) {
      navigate(routes.jobHistories.url);
    }
    else {
      //setCustomerId(_customerId);
      setServiceProfileId(_serviceProfileId);
      setServiceGeneratorId(_serviceGeneratorId);
      setServiceHistoryId(_serviceHistoryId);
      fetchRegularServiceEntry(_serviceProfileId, _serviceGeneratorId, _serviceHistoryId);
    }

    prepareEntrySignatures();
  }, []);

  const prepareEntrySignatures = () => {
    // TODO(yemon): Set to the current entry's creator
    let _currentEmployee = {
      ...auth.userInfo,
    }
    _currentEmployee['id'] = auth.getUserId()['eid'];
    setTestedBy(_currentEmployee);
  }

  const prepareEntryHeaderPayload = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    return {
      'employee_id': auth.getUserId()['eid'],
      'history_type': serviceHistoryTypes.serviceHistory,
      'service_type': serviceTypes.regular,
      'service_profile_id': serviceProfileId,
      'service_generator_id': serviceGeneratorId,
      'service_history_id': serviceHistoryId,
    };
  }

  const fetchRegularServiceEntry = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    let payload = prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId);
    setIsAlertVisible(false);
    setIsLoadingEntry(true);
    portalServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        let _serviceGenerator = _serviceHistory['serviceGenerator'];
        let _serviceLocation = _serviceGenerator['serviceLocation'];
        prepareServiceHistoryDetails(_serviceHistory);
        prepareServiceProfileDetails(_serviceLocation);
        prepareServiceGeneratorDetails(_serviceGenerator);
        prepareRegularEntryDetails(_serviceHistory['generatorTests'], _serviceHistory['testChecklist']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => setIsLoadingEntry(false));
  }

  const prepareServiceHistoryDetails = (serviceHistory) => {
    setServicedDatetime(serviceHistory['servicedDatetime']);

    setReferenceNumber(serviceHistory['referenceNumber']);
    setJobStartTime(serviceHistory['jobStartTime']);
    setJobEndTime(serviceHistory['jobEndTime']);

    setFailureParts(serviceHistory['serviceParts']);
    setRemarks(serviceHistory['remarks']);
    setJobStatus(serviceHistory['status']);
  }

  const prepareServiceProfileDetails = (serviceLocation) => {
    let _serviceProfile = serviceLocation['profile'];
    let _customer = _serviceProfile['customer'];
    setCustomerId(_customer['id']);
    setCustomerName(_customer['customerName']);
    setContactFullName(serviceLocation['contactFullName']);
    setContactNo(serviceLocation['contactNo']);
  }

  const prepareServiceGeneratorDetails = (serviceGenerator) => {
    setServiceGenerator(serviceGenerator);
    let _stock = serviceGenerator['stock'];
    setStock(_stock);
    setGeneratorSerial(_stock['generatorSerial']);
  }

  const prepareRegularEntryDetails = (generatorTests, testChecklist) => {
    if (generatorTests) {
      setRunHours(generatorTests['runHours']);
      setOilPressure(generatorTests['oilPressure']);
      setWaterTemperature(generatorTests['waterTemperature']);
      setEngineRPM(generatorTests['engineRPM']);
      setDcOutputVoltage(generatorTests['dcVoltage']);

      setCheckEngineOil(generatorTests['checkEngineOil']);
      setCheckAirCleanerFilter(generatorTests['checkAirCleanerFilter']);
      setCheckOilFilter(generatorTests['checkOilFilter']);
      setCheckFuelFilter(generatorTests['checkFuelFilter']);
      setCheckWaterRadiatorCap(generatorTests['checkWaterRadiatorCap']);
      setCheckFanBeltBlade(generatorTests['checkFanBeltBlade']);
      setCheckBatteries(generatorTests['checkBatteries']);
      setCheckFuelHosesClamps(generatorTests['checkFuelHosesClamps']);

      setProblemCause(generatorTests['problemCause']);
      setServiceActions(generatorTests['serviceActions']);

      setAftRunHours(generatorTests['aftRunHours']);
      setAftTestingMode(generatorTests['aftTestingMode']);
      setAftTestingPeriod(generatorTests['aftTestingPeriod']);
      setAftOilPressure(generatorTests['aftOilPressure']);
      setAftWaterTemperature(generatorTests['aftWaterTemperature']);
      setAftEngineRPM(generatorTests['aftEngineRPM']);
      setAftDCVoltage(generatorTests['aftDCVoltage']);
      setAftFrequency(generatorTests['aftFrequency']);
      setAftKWHr(generatorTests['aftKWHr']);
    }

    if (testChecklist) {
      setL1N(testChecklist['l1n']);
      setL2N(testChecklist['l2n']);
      setL3N(testChecklist['l3n']);
      setCheckEngineOilLevel(testChecklist['checkEngineOilLevel']);
      setCheckCoolantLevel(testChecklist['checkCoolantLevel']);
      setCheckDriveBeltTension(testChecklist['checkDriveBeltTension']);
      setCheckBatteriesCondition(testChecklist['checkBatteriesCondition']);
      setCheckSensors(testChecklist['checkSensors']);
      setCheckTightenHoldingDownBolts(testChecklist['checkTightenHoldingDownBolts']);
      setCheckWiringConnection(testChecklist['checkWiringConnection']);
      setCheckSwitchAndCircuitBreaker(testChecklist['checkSwitchAndCircuitBreaker']);
      setCheckOilCoolantFuelLeaks(testChecklist['checkOilCoolantFuelLeaks']);
      setCheckHosesPipesClamps(testChecklist['checkHosesPipesClamps']);
      setCheckOilDrainPlug(testChecklist['checkOilDrainPlug']);
    }
  }
  //#endregion

  //#region Utilities
  const getEntryDateClassName = () => {
    let className = "form-control";
    if (servicedDatetime) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
          'generatorId': serviceGeneratorId,
        }
      })
    }, 200);
  }

  const onReturnClicked = (ev) => {
  }

  const onRefreshClicked = (ev) => {
  }

  const onSubmitClicked = (ev, isDraft) => {
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {routes.serviceProfile.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {routes.serviceGenerator.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.regularService.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Regular Service Report Form</h1>
          </div>

          <div className={"form-section"}>
            <h2>Contact Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Serviced Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Reference Number:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Job Start & Finish Times:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <Moment date={servicedDatetime} format={formatters.datetimeShort} />
                </ReadonlyField>
                {/*<DatePicker id={"entryDate"} className={getEntryDateClassName()}*/}
                {/*            placeholder={"Type a valid date for click to choose"}*/}
                {/*            dateFormat={formatters.datetimePicker} minDate={null}*/}
                {/*            required={true} todayButton={"Today"} showWeekNumbers*/}
                {/*            autoComplete={"off"} disabled={isLoadingEntry}*/}
                {/*            selected={entryDate} onChange={(ev) => setEntryDate(ev)} />*/}
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={referenceNumber} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={jobStartTime} /> - <NullBlankValue value={jobEndTime} />
                </ReadonlyField>
              </div>

            </div>
          </div>

          {stock &&
            <ServiceEntryGenerator generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                   gensetModel={stock['generator']['gensetModel']} onGensetModelChanged={null}
                                   generator={stock['manufacturer']} onGeneratorChanged={null}
                                   alternatorSerial={stock['alternatorSerial']} onAlternatorSerialChanged={null}
                                   alternatorModel={stock['alternatorModel']} onAlternatorModelChanged={null}
                                   alternator={stock['alternator']} onAlternatorChanged={null}
                                   machineSerial={stock['machineSerial']} onMachineSerialChanged={null}
                                   machineModel={stock['machineModel']} onMachineModelChanged={null}
                                   machine={stock['machine']} onMachineChanged={null}
                                   controllerSerial={stock['controllerSerial']} onControllerSerialChanged={null}
                                   controllerModel={stock['controllerModel']} onControllerModelChanged={null}
                                   controller={stock['controller']} onControllerChanged={null}
                                   isLoading={isLoadingEntry} isEditable={false}
                                   isSubmitting={false} hasErrors={false} formErrors={null}
            />
          }

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isLoadingEntry || isSubmitting}
                      onClick={onGeneratorSerialClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Generator
              </button>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Before Service Test</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Running Hours:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Oil Pressure:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Water Temperature:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={runHours} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={oilPressure} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={waterTemperature} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Engine RPM:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  DC Output Voltage:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={engineRPM} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={dcOutputVoltage} />
                </ReadonlyField>
              </div>
            </div>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Cause of Genset Service:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Action of Service:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={problemCause} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={serviceActions} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h3>Failure Parts</h3>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Part Description</th>
                <th scope={"col"}>Part</th>
                <th scope={"col"}>Qty</th>
                <th scope={"col"}>Remarks</th>
              </tr>
              </thead>
              <tbody>
              {failureParts && failureParts.length > 0 &&
                failureParts.map((part, index) =>
                  <RegularPartsRow index={index} key={part['id']} part={part} />
                )
              }
              {failureParts && failureParts.length === 0 &&
                <TableEmptyRow colSpan={5} />
              }
              </tbody>
            </table>
          </div>

          <div className={"form-section"}>
            <h3>Next Service</h3>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Recommend Hours:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Engine Oil:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Air Cleaner Filter:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Oil Filter:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Fuel Filter:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={aftRunHours} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkEngineOil} elementId={"checkEngineOil"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkAirCleanerFilter} elementId={"checkAirCleanerFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkOilFilter} elementId={"checkOilFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkFuelFilter} elementId={"checkFuelFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-label-r"}>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Water Radiator Cap:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Fan Belt & Blade:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Batteries:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Fuel Hoses & Clamps:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField></ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkWaterRadiatorCap} elementId={"checkWaterRadiatorCap"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkFanBeltBlade} elementId={"checkFanBeltBlade"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkBatteries} elementId={"checkBatteries"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkFuelHosesClamps} elementId={"checkFuelHosesClamps"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

            </div>
          </div>

          <div className={"form-section"}>
            <h2>After Service Test</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Testing Period:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Oil Pressure:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Water Temperature:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  L1-N:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  L2-N:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  L3-N:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check Engine Oil Level:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check Coolant Level:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check Drive Belt Tension:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check Batteries Condition:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check All Sensors:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Check All Tighten Holding Down Bolts:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={aftTestingPeriod} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={aftOilPressure} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={aftWaterTemperature} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={l1n} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={l2n} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={l3n} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkEngineOilLevel} elementId={"checkEngineOilLevel"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkCoolantLevel} elementId={"checkCoolantLevel"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkDriveBeltTension} elementId={"checkDriveBeltTension"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkBatteriesCondition} elementId={"checkBatteriesCondition"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkSensors} elementId={"checkSensors"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkTightenHoldingDownBolts} elementId={"checkTightenHoldingDownBolts"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-label-r"}>
                <label>
                  Engine RPM:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  DC Output Voltage:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Testing Mode:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Frequency:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  KW Hr:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Check Wiring Connection:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Check Switch & Circuit Breaker:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Check Oil, Coolant & Fuel Leaks:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Check All Hoses, Pipes & Clamps:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Check Oil Drain Plug:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={aftEngineRPM} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={aftDCVoltage} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {aftTestingMode && testMode[aftTestingMode]}
                  {!aftTestingMode && "(NA)"}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={aftFrequency} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={aftKWHr} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkWiringConnection} elementId={"checkWiringConnection"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkSwitchAndCircuitBreaker} elementId={"checkSwitchAndCircuitBreaker"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkOilCoolantFuelLeaks} elementId={"checkOilCoolantFuelLeaks"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkHosesPipesClamps} elementId={"checkHosesPipesClamps"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkOilDrainPlug} elementId={"checkOilDrainPlug"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

            </div>
          </div>

          <div className={"form-section"}>
            <h2>Other Information</h2>

            <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
              <div className={"form-label"}>
                <label htmlFor={"testedBy"}>
                  Remarks:
                </label>
              </div>

              <div className={"form-input"}>
                <div className={"readonly-field"}>
                  <ReadonlyField>
                    <NullBlankValue value={remarks} />
                  </ReadonlyField>
                </div>
              </div>
            </div>
          </div>

          <ServiceEntrySignature jobStatus={jobStatus} testedBy={testedBy} />

          <div className={"form-section-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={isLoadingEntry || isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, false)}>
                {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
                Submit
              </button>

              <button type={"button"} className={"btn btn-secondary"} disabled={isLoadingEntry || isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, true)}>
                {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
                Save Draft
              </button>
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={onAlertDismissButtonClicked}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const RegularPartsRow = ({ part, index }) => {
  return (
    <tr>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        {part['partDescription']}
      </td>
      <td>
        {part['partName']}
      </td>
      <td>
        {part['quantity']}
      </td>
      <td>
        {part['remarks']}
      </td>
    </tr>
  )
}
