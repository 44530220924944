export const DEFAULT_LIST_PAGE_SIZE = 10;
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const formatters = {
  datetime: "MMMM DD, yyyy",
  datetimeShort: "MMM DD, yyyy",
  datetimeLiteral: "yyyyMMDD",
  datetimePicker: "MMMM dd, yyyy",
  datetimePickerShort: "MMM dd, yyyy",
}

export const navigableModules = {
  service: {
    id: 2,
    name: "Service",
  },
}

export const navigableRoutes = {
  login: {
    id: 1,
    url: "/login",
    display: "Login",
    displayShort: "Login",
    faIcon: "",
  },
  dashboard: {
    id: 2,
    url: "/",
    display: "Dashboard",
    displayShort: "Dashboard",
    faIcon: "fa-chart-pie",
  },

  serviceProfile: {
    id: 21,
    url: "/service/profile",
    display: "Service Profile",
    displayShort: "Service Profile",
    faIcon: "fa-book",
  },
  serviceGenerator: {
    id: 211,
    url: "/service/profile/generator",
    display: "Service Generator",
    displayShort: "Service Generator",
    faIcon: "",
  },
  testingAndCommission: {
    id: 212,
    url: "/service/profile/generator/tnc",
    display: "Testing & Commission",
    displayShort: "T&C",
    faIcon: "",
  },
  preventiveMaintenance: {
    id: 213,
    url: "/service/profile/generator/pm",
    display: "Preventive Maintenance",
    displayShort: "PM",
    faIcon: "",
  },
  regularService: {
    id: 214,
    url: "/service/profile/generator/regular",
    display: "Regular",
    displayShort: "Regular Service",
    faIcon: "",
  },
  repairService: {
    id: 215,
    url: "/service/profile/generator/repair",
    display: "Repair",
    displayShort: "Repair Service",
    faIcon: "",
  },
  emergencyBreakdown: {
    id: 216,
    url: "/service/profile/generator/emergency",
    display: "Emergency Breakdown",
    displayShort: "Emergency Breakdown",
    faIcon: "",
  },
  inspection: {
    id: 217,
    url: "/service/profile/generator/inspection",
    display: "Inspection",
    displayShort: "Inspection",
    faIcon: "",
  },
  careContactLogEntry: {
    id: 218,
    url: "/service/profile/generator/care-contact-log",
    display: "Care Contact Log",
    displayShort: "Contact Log",
    faIcon: "",
  },

}

const composeFollowUpStagesRef = function() {
  return [
    {
      id: 1,
      name: "Lead",
      actions: [
        { id: '11', name: "Intro" },
        { id: '12', name: "Inquiry Price" },
        { id: '13', name: "Inquiry Interest" },
        { id: '14', name: "Follow up" },
        { id: '15', name: "Uninterested" },
      ],
    },
    {
      id: 2,
      name: "Prospect",
      actions: [
        { id: '21', name: "Follow up" },
        { id: '22', name: "Uninterested" },
      ],
    },
    {
      id: 3,
      name: "Quotation",
      actions: [
        { id: '31', name: "Prepare/Submit request" },
        { id: '32', name: "Follow up" },
        { id: '33', name: "Submit to customer" },
        { id: '34', name: "Postponed" },
      ],
    },
    {
      id: 4,
      name: "Deal",
      actions: [
        { id: '41', name: "Negotiate" },
      ],
    },
    {
      id: 5,
      name: "Win",
      actions: [
        { id: '51', name: "Order confirmed" },
        { id: '52', name: "Credit Collect" },
      ],
    },
    {
      id: 6,
      name: "Lost",
      actions: [
        { id: '61', name: "Due to unavailability" },
        { id: '62', name: "Due to high price" },
        { id: '63', name: "Other reasons" },
      ],
    },
  ];
}

const composeFollowUpStagesConstants = () => {
  const stages = composeFollowUpStagesRef();
  return {
    lead: stages[0].id, 1: stages[0].name,
    prospect: stages[1].id, 2: stages[1].name,
    quotation: stages[2].id, 3: stages[2].name,
    deal: stages[3].id, 4: stages[3].name,
    win: stages[4].id, 5: stages[4].name,
    lost: stages[5].id, 6: stages[5].name,
  }
}

const composeInstallmentOptions = () => {
  return [
    { id: 0, name: "No installment", },
    { id: 1, name: "1 month" },
    { id: 2, name: "2 months" },
    { id: 3, name: "3 months" },
    { id: 4, name: "4 months" },
    { id: 5, name: "5 months" },
    { id: 6, name: "6 months" },
    { id: 7, name: "7 months" },
    { id: 8, name: "8 months" },
    { id: 9, name: "9 months" },
    { id: 10, name: "10 months" },
    { id: 11, name: "11 months" },
    { id: 12, name: "12 months" },
  ];
}

const composePaymentTermOptions = () => {
  return [
    { id: 1, name: 'Cash' },
    { id: 2, name: 'Credit' },
  ]
}

const composeReviewStatusOptions = () => {
  return [
    { id: 0, name: "Draft" },
    { id: 1, name: "Pending Review" },
    { id: 2, name: "Approved" },
    { id: 3, name: "Rejected" },
    { id: 8, name: "Canceled" },
  ];
}

export const refs = {
  reference: {
  },
  sales: {
    source: {
      1: {
        "name": "Social Channel/Marketing Lead",
        "description": "Facebook, Twitter, YouTube, company website or leads from Marketing department.",
        "code": "SCML",
      },
      2: {
        "name": "Own",
        "description": "Self initiative leads.",
        "code": "OWN",
      },
      3: {
        "name": "Other",
        "description": "Others and miscellaneous.",
        "code": "OTH",
      },
      9: {
        "name": "(NA)",
        "description": "(NA)",
        "code": "(NA)",
      },
    },
    sourceHtml: {
      1: {
        "name": "Social Channel/<br/>Marketing Lead",
        "description": "Facebook, Twitter, YouTube, company website or leads from Marketing department.",
        "code": "SCML",
      },
      2: {
        "name": "Own",
        "description": "Self initiative leads.",
        "code": "OWN",
      },
      3: {
        "name": "Other",
        "description": "Others and miscellaneous.",
        "code": "OTH",
      },
      9: {
        "name": "(NA)",
        "description": "(NA)",
        "code": "(NA)",
      },
    },
    followUpStages: composeFollowUpStagesRef(),
    followUpStagesConstants: composeFollowUpStagesConstants(),
    // followUpActions: composeFollowUpActionsRef(),
    paymentInstallmentOptions: composeInstallmentOptions(),
    assignTypes: {
      1: "Primary", primary: 1,
      2: "Secondary", secondary: 2,
    },
    isFormOwner: {
      yes: 1,
      no: 0,
      inapplicable: -1,
    },
    reviewStatusOptions: composeReviewStatusOptions(),
    reviewStatus: {
      0: "Draft", draft: 0,
      1: "Pending Review", pendingReview: 1,
      2: "Approved", approved: 2,
      3: "Rejected", rejected: 3,
      8: "Canceled", canceled: 8,
    },
    activeStatus: {
      0: "Draft", draft: 0,
      1: "Active", active: 1,
      8: "Canceled", canceled: 8,
      9: "Inactive", inactive: 9,
    },
    draftState: {
      submitDraft: 0,
      submitReview: 1,
      submitReviewUpdate: 2,
    },
    paymentTermOptions: composePaymentTermOptions(),
    paymentTerm: {
      1: "Cash", cash: 1,
      2: "Credit", credit: 2,
    },
    poDurations: {
      0: "(Unspecified)", unspecified: 0,
      1: "3 months", threeMonths: 1,
      2: "6 months", sixMonths: 2,
      3: "1 year", oneYear: 3,
      9: "(Custom)", custom: 9,
    },
    poDurationOptions: function() {
      const poDurations = refs.sales.poDurations;
      return [
        { id: poDurations.unspecified, name: poDurations[0] },
        { id: poDurations.threeMonths, name: poDurations[1] },
        { id: poDurations.sixMonths, name: poDurations[2] },
        { id: poDurations.oneYear, name: poDurations[3] },
        { id: poDurations.custom, name: poDurations[9] },
      ]
    },
  },
  currency: {
    "usd": {
      code: 1,
      name: "US Dollars",
      sign: "$",
    },
    "mmk": {
      code: 2,
      name: "Myanmar Kyats",
      sign: "MMK",
    },
  },
  inventory: {
    generatorType: {
      0: "(NA)", none: 0,
      1: "Open", open: 1,
      2: "Canopy", canopy: 2,
    },
    generatorManufacturers: {
      0: "-Any-", any: 0,
      99: "(Unspecified)", unspecified: 99,
      1: "AKSA", AKSA: 1,
      2: "DANYO", DANYO: 2,
      3: "Kohler", kohler: 3,
      4: "New Holland", newHolland: 4,
      5: "Power Link", powerLink: 5,
      6: "Local", local: 6,
    },
    generatorAlternators: {
      0: "-Any-", any: 0,
      99: "(Unspecified)", unspecified: 99,
      1: "AKSA", AKSA: 1,
      2: "STAMFORD", Stamford: 2,
      3: "Mecc Alte", meccAlte: 3,
      4: "Leroy Somer", leroySomer: 4,
      5: "Marathon", marathon: 5,
      6: "Copy-STAMFORD", copyStamford: 6,
      7: "FPT", fpt: 7,
      8: "HOKUETSU", hokuetsu: 8,
      9: "Brushless", brushless: 9,
    },
    generatorMachines: {
      0: "-Any-", any: 0,
      99: "(Unspecified)", unspecified: 99,
      1: "AKSA", AKSA: 1,
      2: "Cummins", Cummins: 2,
      3: "Mitsubishi", Mitsubishi: 3,
      4: "Perkins", Perkins: 4,
      5: "Volvo", volvo: 5,
      6: "FPT", fpt: 6,
      7: "John Deere", johnDeere: 7,
      8: "ISUZU", isuzu: 8,
      9: "KUBOTA", kubota: 9,
      10: "Hino", hino: 10,
      11: "Weichai", weichai: 11,
    },
    generatorControllers: {
      0: "-Any-", any: 0,
      99: "(Unspecified)", unspecified: 99,
      1: "Deep Sea", deepSea: 1,
      2: "ComAp", compAp: 2,
      3: "Smart Gen", smartGen: 3,
      4: "Kohler", kohler: 4,
      5: "DEIF", deif: 5,
      6: "FPT", fpt: 6,
      7: "Analog", analog: 7,
    },
    testMode: {
      0: "(NA)", na: 0,
      1: "On Load", onLoad: 1,
      2: "Off Load", offLoad: 2,
    },
    governorType: {
      1: "Electronic", electronic: 1,
      2: "Mechanical", mechanical: 2,
    },
    jobStatus: {
      0: "Draft", draft: 0,
      1: "Pending Review", pendingReview: 1,
      2: "Active", active: 2,
    },
    stockStatus: {
      0: "Draft", draft: 0,
      1: "Under Inspection", underInspection: 1,
      2: "Available", available: 2,
      3: "Commissioned", commissioned: 3,
      4: "Inactive", inactive: 4,
    },
    stockInspectionType: {
      1: "Arrival Inspection", arrival: 1,
      2: "Pre-Delivery Inspection", predelivery: 2,
      3: "Monthly Inspection", monthly: 3,
      9: "(Imported)", imported: 9,
    },
    serviceProfileStatus: {
      1: "Active", active: 1,
      0: "Inactive", inactive: 0,
    },
    serviceGeneratorCommissionType: {
      0: "(N/A)", na: 0,
      1: "PTI", pti: 1,
      2: "PTI Contract", ptiContract: 2,
      3: "Other", other: 3,
      4: "Other Contract", otherContract: 4,
    },
    serviceGeneratorCommissionStatus: {
      0: "Draft", draft: 0,
      1: "Active", active: 1,
      9: "Inactive", inactive: 9,
    },
    serviceGeneratorWarrantyStatus: {
      true: "Under-warranty",
      false: "Over-warranty",
    },
    serviceHistoryType: {
      1: "Technical Service", serviceHistory: 1,
      2: "Customer Care Contact", contactHistory: 2,
    },
    serviceType: {
      0: "(Not applicable)", na: 0,
      1: "Testing & Commissioning", tnc: 1,
      2: "Preventive Maintenance", pm: 2,
      3: "Regular Service", regular: 3,
      4: "Repair Service", repair: 4,
      5: "Emergency Breakdown", emergency: 5,
      6: "Inspection", inspection: 6,
    },
    testChecklist: {
      1: "Normal", normal: 1,
      2: "Abnormal", abnormal: 2,
    },
  },
  customer: {
    callType: {
      1: "Outbound", outbound: 1,
      2: "Inbound", inbound: 2,
    },
    contactType: {
      0: "(Not applicable)", na: 0,
      1: "Regular care call", regularCall: 1,
      2: "Post-service follow up call", postServiceCall: 2,
      3: "Inquiry", inquiry: 3,
      4: "Complaint", complaint: 4,
    },
    satisfaction: {
      0: "(Not specified)", na: 0,
      1: "Satisfied", satisfied: 1,
      2: "Neutral", neutral: 2,
      3: "Dissatisfied", dissatisfied: 3,
    },
    jobStatus: {
      0: "Draft", draft: 0,
      1: "Pending Review", pendingReview: 1,
      2: "Active", active: 2,
    },
  },
  portal: {
    accountStatus: {
      0: "Draft", draft: 0,
      1: "Password Reset", passwordReset: 1,
      2: "Active", active: 2,
      3: "No Activity", noActivity: 3,
      9: "Inactive", inactive: 9,
    },
  },
}

export const role = {
  salesInquiry: {
    detailsEditLevels: {
      1: "Review update requests", reviewer: 1,
      2: "Allow update request", requester: 2,
      3: "Read-only after add", readOnly: 3,
    },
    historyEditLevels: {
      1: "Review update requests", reviewer: 1,
      2: "Allow update requests", requester: 2,
      3: "Read-only after new history added", readOnly: 3,
    },
  },

  quotationRequest: {
    reviewLevels: {
      1: "Submit and modify requests", requester: 1,
      2: "Review submitted requests", reviewer: 2,
      3: "Read-only", readOnly: 3,
    },
    cancelLevels: {
      1: "Requester", requester: 1,
      2: "Reviewer", reviewer: 2,
    },
  },

  orderConfirmation: {
    listingViewScope: {
      self: "self",
      all: "all",
      none: "none",
    },
    reviewLevels: { // NOTE: Might need to combine this with quotationRequest.reviewLevels later
      1: "Submit and modify requests", requester: 1,
      2: "Review submitted requests", reviewer: 2,
      3: "Read-only", readOnly: 3,
    },
    editLevels: {
      1: "Full control", fullControl: 1,
      2: "Can create new", canCreateNew: 2,
      3: "Read-only", readOnly: 3,
    },
    cancelLevels: {
      1: "Requester", requester: 1,
      2: "Reviewer", reviewer: 2,
    },
  },

  cancelLevels: {
    1: "Requester", requester: 1,
    2: "Reviewer", reviewer: 2,
  },
}

export const customer = {
  profileStatus: {
    0: "Draft", draft: 0,
    1: "Active", active: 1,
    9: "Inactive", inactive: 9,
  }
}

export const utils = {
  sales: {
    getPrimaryReporterFromInquiry: function(inquiry) {
      if (!inquiry) return {};
      return this.getPrimaryReporter(inquiry['reporters']);
    },
    getPrimaryReporter: function(reporters) {
      if (!reporters || reporters.length === 0) return {};
      return reporters.filter(reporter => reporter['assignType'] === refs.sales.assignTypes.primary)[0]['salesPerson'];
    },
    getSecondaryReporters: function(reporters) {
      if (!reporters || reporters.length === 0) return [];
      return reporters.filter(reporter => reporter['assignType'] === refs.sales.assignTypes.secondary);
    }
  }
}

export const formModes = {
  quotationRequest: {
    newEntry: 0, // new form
    editEntry: 1, // editing customer details
    quotationEntry: 2, // adding quotation details items
  },
  orderConfirmation: {
    newEntry: 0, // new form
    editCustomer: 1, // editing customer details, the rest of the form elements are disabled
    editEntry: 2, // customer details in read-only mode, the rest of the form elements enabled
  },
}
