import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { portalServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { displayColumn } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestChecklist } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestResultsList } from "./ServiceEntryTestResultsList";
import { ServiceEntrySignature } from "./ServiceEntrySignature";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "./ServiceProfilePage.css";


const generatorManufacturers = refs.inventory.generatorManufacturers;
const generatorAlternators = refs.inventory.generatorAlternators;
const stockStatuses = refs.inventory.stockStatus;
const jobStatuses = refs.inventory.jobStatus;
const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;

export function TestingAndCommissionEntryPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceGeneratorId, setServiceGeneratorId] = useState(null);
  const [serviceHistoryId, setServiceHistoryId] = useState(null);

  const [isLoadingServiceEntry, setIsLoadingServiceEntry] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [servicedDatetime, setServicedDatetime] = useState(new Date());
  const [installationDate, setInstallationDate] = useState(new Date());
  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [stock, setStock] = useState(null);
  const [generatorSerial, setGeneratorSerial] = useState("");

  const [isEditable, setIsEditable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("Hi there!");

  const [jobStatus, setJobStatus] = useState(jobStatuses.draft);
  const [testedBy, setTestedBy] = useState({});
  const [checkedBy, setCheckedBy] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region States; Test Results form sections
  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const _testChecklist = refs.inventory.testChecklist;  // NOTE(yemon): Value reference for the following checklist fields.
  const [testChecklistId, setTestChecklistId] = useState(null);
  const [checkEngineCondition, setCheckEngineCondition] = useState(null);
  const [checkFuelLevel, setCheckFuelLevel] = useState(null);
  const [checkCoolantLevel, setCheckCoolantLevel] = useState(null);
  const [checkEngineOilLevel, setCheckEngineOilLevel] = useState(null);
  const [checkBatteriesCondition, setCheckBatteriesCondition] = useState(null);
  const [checkDriveBeltTension, setCheckDriveBeltTension] = useState(null);
  const [checkControlPanelBox, setCheckControlPanelBox] = useState(null);
  const [checkSwitchNCircuitBreaker, setCheckSwitchNCircuitBreaker] = useState(null);
  const [checkHosesPipesClamps, setCheckHosesPipesClamps] = useState(null);
  const [checkWiringCondition, setCheckWiringCondition] = useState(null);
  const [checkExhaustCondition, setCheckExhaustCondition] = useState(null);
  const [checkEarthWire, setCheckEarthWire] = useState(null);
  const [checkStarterMotor, setCheckStarterMotor] = useState(null);
  const [checkEngineFoundation, setCheckEngineFoundation] = useState(null);

  const [checkFuelOilPressure, setCheckFuelOilPressure] = useState(null);
  const [checkWaterTemperature, setCheckWaterTemperature] = useState(null);
  const [checkBatteryChargingVolts, setCheckBatteryChargingVolts] = useState(null);
  const [checkEngineRPM, setCheckEngineRPM] = useState(null);
  const [checkFrequency, setCheckFrequency] = useState(null);
  const [checkL1V, setCheckL1V] = useState(null);
  const [checkL2V, setCheckL2V] = useState(null);
  const [checkL3V, setCheckL3V] = useState(null);
  const [checkL1NV, setCheckL1NV] = useState(null);
  const [checkL2NV, setCheckL2NV] = useState(null);
  const [checkL3NV, setCheckL3NV] = useState(null);
  const [checkL1C, setCheckL1C] = useState(null);
  const [checkL2C, setCheckL2C] = useState(null);
  const [checkL3C, setCheckL3C] = useState(null);
  const [checkLoadTestKVA, setCheckLoadTestKVA] = useState(null);

  const [groupType, setGroupType] = useState("");
  const [runningHours, setRunningHours] = useState("");
  const [testResults, setTestResults] = useState([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.jobHistories.url);
    }
    let _serviceProfileId = state['serviceProfileId'];
    let _serviceGeneratorId = state['serviceGeneratorId'];
    let _serviceHistoryId = state['serviceHistoryId'];
    if (!_serviceProfileId || !_serviceGeneratorId || !_serviceHistoryId) {
      navigate(routes.jobHistories.url);
    }
    else {
      setServiceProfileId(_serviceProfileId);
      setServiceGeneratorId(_serviceGeneratorId);
      setServiceHistoryId(_serviceHistoryId);
      fetchCommissionServiceEntry(_serviceProfileId, _serviceGeneratorId, _serviceHistoryId);
    }

    // NOTE(yemon): Portal has to be in permanent readonly mode, for now
    setIsFormReadonly(true);
  }, []);

  const prepareEntryHeaderPayload = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    return {
      'history_type': serviceHistoryTypes.serviceHistory,
      'service_type': serviceTypes.tnc,
      'service_profile_id': serviceProfileId,
      'service_generator_id': serviceGeneratorId,
      'service_history_id': serviceHistoryId,
    };
  }

  const fetchCommissionServiceEntry = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    let payload = prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId);
    setIsAlertVisible(false);
    setIsLoadingServiceEntry(true);
    portalServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        let _serviceGenerator = _serviceHistory['serviceGenerator'];
        let _serviceLocation = _serviceGenerator['serviceLocation'];
        prepareServiceHistoryDetails(_serviceHistory);
        prepareServiceProfileDetails(_serviceLocation);
        prepareServiceGeneratorDetails(_serviceGenerator);
        prepareCommissionEntryDetails(_serviceHistory['testChecklist']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => setIsLoadingServiceEntry(false));
  }

  const prepareServiceHistoryDetails = (serviceHistory) => {
    setServicedDatetime(serviceHistory['servicedDatetime'])
    setJobStatus(serviceHistory['status']);

    setTestedBy(serviceHistory['servicedBy']);
    setCheckedBy(serviceHistory['reviewedBy']);
  }

  const prepareServiceProfileDetails = (serviceLocation) => {
    let _serviceProfile = serviceLocation['profile'];
    let _customer = _serviceProfile['customer'];
    setCustomerId(_customer['id']);
    setCustomerName(_customer['customerName']);
    setContactFullName(serviceLocation['contactFullName']);
    setContactNo(serviceLocation['contactNo']);
  }

  const prepareServiceGeneratorDetails = (serviceGenerator) => {
    setServiceGenerator(serviceGenerator);
    setInstallationDate(serviceGenerator['installationDate'])
    let _stock = serviceGenerator['stock'];
    setStock(_stock);
    setGeneratorSerial(_stock['generatorSerial']);

    // NOTE(yemon): Originally supposed to be set from testChecklist['groupType']
    setGroupType(serviceGenerator['gensetType']);
  }

  const prepareCommissionEntryDetails = (testChecklist) => {
    if (!testChecklist) {
      return;
    }

    setTestChecklistId(testChecklist['id']);
    setCheckEngineCondition(testChecklist['checkEngineCondition']);
    setCheckFuelLevel(testChecklist['checkFuelLevel']);
    setCheckCoolantLevel(testChecklist['checkCoolantLevel']);
    setCheckEngineOilLevel(testChecklist['checkEngineOilLevel']);
    setCheckBatteriesCondition(testChecklist['checkBatteriesCondition']);
    setCheckDriveBeltTension(testChecklist['checkDriveBeltTension']);
    setCheckControlPanelBox(testChecklist['checkControlPanelBox']);
    setCheckSwitchNCircuitBreaker(testChecklist['checkSwitchAndCircuitBreaker']);
    setCheckHosesPipesClamps(testChecklist['checkHosesPipesClamps']);
    setCheckWiringCondition(testChecklist['checkWiringCondition']);
    setCheckExhaustCondition(testChecklist['checkExhaustCondition']);
    setCheckEarthWire(testChecklist['checkEarthWire']);
    setCheckStarterMotor(testChecklist['checkStarterMotor']);
    setCheckEngineFoundation(testChecklist['checkEngineFoundation']);

    setCheckFuelOilPressure(testChecklist['checkFuelOilPressure']);
    setCheckWaterTemperature(testChecklist['checkWaterTemperature']);
    setCheckBatteryChargingVolts(testChecklist['checkBatteryChargingVolts']);
    setCheckEngineRPM(testChecklist['checkEngineRPM']);
    setCheckFrequency(testChecklist['checkFrequency']);
    setCheckL1V(testChecklist['checkL1V']);
    setCheckL2V(testChecklist['checkL2V']);
    setCheckL3V(testChecklist['checkL3V']);
    setCheckL1NV(testChecklist['checkL1NV']);
    setCheckL2NV(testChecklist['checkL2NV']);
    setCheckL3NV(testChecklist['checkL3NV']);
    setCheckL1C(testChecklist['checkL1C']);
    setCheckL2C(testChecklist['checkL2C']);
    setCheckL3C(testChecklist['checkL3C']);
    setCheckLoadTestKVA(testChecklist['checkLoadTestKVA']);

    setRunningHours(testChecklist['runningHours']);
    setTestResults(testChecklist['details']);
  }
  //#endregion

  //#region Effects; Test Results form sections
  useEffect(() => {
    // NOTE(yemon): form's 'readonly' mode should not be dynamic for the Portal
    // if (jobStatus === jobStatuses.draft) {
    //   setIsFormReadonly(false);
    // }
    // else {
    //   setIsFormReadonly(true);
    // }
  }, [jobStatus]);
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
          'generatorId': serviceGeneratorId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Control handlers; Test Results form sections
  const prepareTestResultsPayload = () => {
    let json = {
      'id': testChecklistId,
      'service_history_id': serviceHistoryId,
      'check_engine_condition': checkEngineCondition,
      'check_fuel_level': checkFuelLevel,
      'check_coolant_level': checkCoolantLevel,
      'check_engine_oil_level': checkEngineOilLevel,
      'check_batteries_condition': checkBatteriesCondition,
      'check_drive_belt_tension': checkDriveBeltTension,
      'check_control_panel_box': checkControlPanelBox,
      'check_switch_and_circuit_breaker': checkSwitchNCircuitBreaker,
      'check_hoses_pipes_clamps': checkHosesPipesClamps,
      'check_wiring_condition': checkWiringCondition,
      'check_exhaust_condition': checkExhaustCondition,
      'check_earth_wire': checkEarthWire,
      'check_starter_motor': checkStarterMotor,
      'check_engine_foundation': checkEngineFoundation,
      'check_fuel_oil_pressure': checkFuelOilPressure,
      'check_water_temperature': checkWaterTemperature,
      'check_battery_charging_volts': checkBatteryChargingVolts,
      'check_engine_rpm': checkEngineRPM,
      'check_frequency': checkFrequency,
      'check_l1v': checkL1V,
      'check_l2v': checkL2V,
      'check_l3v': checkL3V,
      'check_l1nv': checkL1NV,
      'check_l2nv': checkL2NV,
      'check_l3nv': checkL3NV,
      'check_l1c': checkL1C,
      'check_l2c': checkL2C,
      'check_l3c': checkL3C,
      'check_load_test_kva': checkLoadTestKVA,
    };
    return json
  }

  const onSubmitClicked = (ev, isDraft) => {
    let _hasErrors = false;
    if (_hasErrors) {
      setAlertMessage("Please fix the indicated errors on the respective fields before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      return;
    }
    setIsAlertVisible(false);

    let payload = {
      ...prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId),
      'job_status': isDraft ? jobStatuses.draft : jobStatuses.pendingReview,
      'test_checklist': prepareTestResultsPayload(serviceHistoryId),
    };

    setIsSubmitting(true);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    }
    else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }

    inventoryServices.submitServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _responseData = response['data'];
        console.log(_responseData);
        setAlertType(alertTypes.info);
        setAlertMessage(_responseData['message']);
        setIsAlertVisible(true);

        setJobStatus(_responseData['jobStatus']);
        setTestChecklistId(_responseData['testChecklistId']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
        setIsSubmitting(false);
      });
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {routes.serviceProfile.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {routes.serviceGenerator.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.testingAndCommission.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Testing & Commission Service Form</h1>
          </div>

          <div className={"form-section"}>
            <h2>Contact Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"} title={"Location name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div title={"Location address"}>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Serviced Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Installation Date:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {servicedDatetime && <Moment date={servicedDatetime} format={formatters.datetimeShort} />}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {installationDate && <Moment date={installationDate} format={formatters.datetimeShort} />}
                </ReadonlyField>
              </div>
            </div>
          </div>

          {stock &&
            <ServiceEntryGenerator generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                   gensetModel={stock['generator']['gensetModel']} onGensetModelChanged={null}
                                   generator={stock['manufacturer']} onGeneratorChanged={null}
                                   alternatorSerial={stock['alternatorSerial']} onAlternatorSerialChanged={null}
                                   alternatorModel={stock['alternatorModel']} onAlternatorModelChanged={null}
                                   alternator={stock['alternator']} onAlternatorChanged={null}
                                   machineSerial={stock['machineSerial']} onMachineSerialChanged={null}
                                   machineModel={stock['machineModel']} onMachineModelChanged={null}
                                   machine={stock['machine']} onMachineChanged={null}
                                   controllerSerial={stock['controllerSerial']} onControllerSerialChanged={null}
                                   controllerModel={stock['controllerModel']} onControllerModelChanged={null}
                                   controller={stock['controller']} onControllerChanged={null}
                                   isLoading={isLoadingServiceEntry} isEditable={false}
                                   isSubmitting={false} hasErrors={false} formErrors={null}
            />
          }

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isSubmitting || !isEditable}
                      onClick={onGeneratorSerialClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Generator
              </button>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Test Result Details</h2>
          </div>

          <TabsPanelProvider initialHeaderElementId={"checklist-tab"}
                             initialContentPaneElementId={"checklist-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"checklist-tab"} contentPaneElementId={"checklist-content"}
                             itemLabel={"Test Checklist"} />
              <TabHeaderItem itemElementId={"test-results-tab"} contentPaneElementId={"test-results-content"}
                             itemLabel={"Test Results"} />
            </TabHeader>
            <TabContent>
              <TabContentPane contentPaneElementId={"checklist-content"}>
                <ServiceEntryTestChecklist isFormReadonly={isFormReadonly} isFormDisabled={isFormDisabled}
                                           checkEngineCondition={checkEngineCondition} setCheckEngineCondition={setCheckEngineCondition}
                                           checkFuelLevel={checkFuelLevel} setCheckFuelLevel={setCheckFuelLevel}
                                           checkCoolantLevel={checkCoolantLevel} setCheckCoolantLevel={setCheckCoolantLevel}
                                           showCheckOilCleaner={false}
                                           checkEngineOilLevel={checkEngineOilLevel} setCheckEngineOilLevel={setCheckEngineOilLevel}
                                           checkBatteriesCondition={checkBatteriesCondition} setCheckBatteriesCondition={setCheckBatteriesCondition}
                                           checkDriveBeltTension={checkDriveBeltTension} setCheckDriveBeltTension={setCheckDriveBeltTension}
                                           checkControlPanelBox={checkControlPanelBox} setCheckControlPanelBox={setCheckControlPanelBox}
                                           checkSwitchNCircuitBreaker={checkSwitchNCircuitBreaker} setCheckSwitchNCircuitBreaker={setCheckSwitchNCircuitBreaker}
                                           showCheckWiringConnection={false}
                                           showCheckOilCoolantFuelLeaks={false}
                                           checkHosesPipesClamps={checkHosesPipesClamps} setCheckHosesPipesClamps={setCheckHosesPipesClamps}
                                           checkWiringCondition={checkWiringCondition} setCheckWiringCondition={setCheckWiringCondition}
                                           checkExhaustCondition={checkExhaustCondition} setCheckExhaustCondition={setCheckExhaustCondition} exhaustConditionColumn={displayColumn.left}
                                           checkEarthWire={checkEarthWire} setCheckEarthWire={setCheckEarthWire}
                                           checkStarterMotor={checkStarterMotor} setCheckStarterMotor={setCheckStarterMotor} starterMotorColumn={displayColumn.left}
                                           checkEngineFoundation={checkEngineFoundation} setCheckEngineFoundation={setCheckEngineFoundation}
                                           showCheckATS={false}
                                           checkFuelOilPressure={checkFuelOilPressure} setCheckFuelOilPressure={setCheckFuelOilPressure}
                                           checkWaterTemperature={checkWaterTemperature} setCheckWaterTemperature={setCheckWaterTemperature}
                                           checkBatteryChargingVolts={checkBatteryChargingVolts} setCheckBatteryChargingVolts={setCheckBatteryChargingVolts}
                                           checkEngineRPM={checkEngineRPM} setCheckEngineRPM={setCheckEngineRPM}
                                           checkFrequency={checkFrequency} setCheckFrequency={setCheckFrequency}
                                           checkL1V={checkL1V} setCheckL1V={setCheckL1V}
                                           checkL2V={checkL2V} setCheckL2V={setCheckL2V}
                                           checkL3V={checkL3V} setCheckL3V={setCheckL3V}
                                           checkL1NV={checkL1NV} setCheckL1NV={setCheckL1NV}
                                           checkL2NV={checkL2NV} setCheckL2NV={setCheckL2NV}
                                           checkL3NV={checkL3NV} setCheckL3NV={setCheckL3NV}
                                           checkL1C={checkL1C} setCheckL1C={setCheckL1C}
                                           checkL2C={checkL2C} setCheckL2C={setCheckL2C}
                                           checkL3C={checkL3C} setCheckL3C={setCheckL3C}
                                           checkLoadTestKVA={checkLoadTestKVA} setCheckLoadTestKVA={setCheckLoadTestKVA}
                                           showCheckRecordRunningHours={false}
                                           showCheckAlternator={false}
                                           showCheckControlSystem={false}
                                           showCheckAirInletFilter={false}
                                           showCheckTightenHoldingDownBolts={false}
                                           showCheckCleanAroundGenerator={false}
                                           showCheckRecordVoltsHz={false}
                                           showCheckEngineOilPressure={false}
                                           showCheckTestOffLoadOnLoad={false}
                />
              </TabContentPane>
              <TabContentPane contentPaneElementId={"test-results-content"}>
                <ServiceEntryTestResultsList isFormReadonly={isFormReadonly} isFormDisabled={isFormDisabled}
                                             groupType={groupType} setGroupType={setGroupType}
                                             runningHours={runningHours} setRunningHours={setRunningHours}
                                             isLoading={isLoadingServiceEntry} testResults={testResults}
                />
              </TabContentPane>
            </TabContent>
          </TabsPanelProvider>

          <ServiceEntrySignature jobStatus={jobStatus} testedBy={testedBy} />

          <div className={"form-section-controls"}>
            {/*<div className={"left-side"}>*/}
            {/*  <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting || !isEditable || isFormReadonly}*/}
            {/*          onClick={(ev) => onSubmitClicked(ev, false)}>*/}
            {/*    {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}*/}
            {/*    {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}*/}
            {/*    Submit*/}
            {/*  </button>*/}

            {/*  <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || !isEditable || isFormReadonly}*/}
            {/*          onClick={(ev) => onSubmitClicked(ev, true)}>*/}
            {/*    {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}*/}
            {/*    {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}*/}
            {/*    Save Draft*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={onAlertDismissButtonClicked}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
