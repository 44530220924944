import React from "react";
import './Footer.css';


export const Footer = (props) => {
  const version = "v1.2.6";
  const year = "2024";

  return (
    <>
      {props.fullWidth &&
        <div className={"footer-fullwidth"}>
          {version} &copy; {year}, PTI Company Limited
        </div>
      }

      {!props.fullWidth &&
        <div className={"footer-container"}>
          <div className={"content-container"}>
            <span>{version} &copy; {year}, PTI Company Limited</span>
          </div>
        </div>
      }
    </>
  )

}
