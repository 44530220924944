import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";

import { refs } from "../../repos/constants";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";

const manufacturers = refs.inventory.generatorManufacturers;
const alternators = refs.inventory.generatorAlternators;
const machines = refs.inventory.generatorMachines;
const controllers = refs.inventory.generatorControllers;

export const ServiceEntryGenerator = ({
                                        gensetModel, onGensetModelChanged,
                                        generatorSerial, onGeneratorSerialChanged,
                                        generator, onGeneratorChanged,
                                        alternatorSerial, onAlternatorSerialChanged,
                                        alternatorModel, onAlternatorModelChanged,
                                        alternator, onAlternatorChanged,
                                        machineSerial, onMachineSerialChanged,
                                        machineModel, onMachineModelChanged,
                                        machine, onMachineChanged,
                                        controllerSerial, onControllerSerialChanged,
                                        controllerModel, onControllerModelChanged,
                                        controller, onControllerChanged,
                                        isLoading, isEditable, isSubmitting, hasErrors, formErrors,
                                      }) => {
  //#region States
  //#endregion

  //#region Effects
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={"form-section"}>
      <h2>Generator Details</h2>

      <div className={"entry-form arrival-inspection-form generator-section"}>
        <div className={"form-label"}>
          <label htmlFor={"generatorSerial"}>
            Generator Serial Number:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"generatorModel"}>
            Generator Model:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"generator"}>
            Generator/Manufacturer:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternatorSerial"}>
            Alternator Serial Number:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternatorModel"}>
            Alternator Model:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternator"}>
            Alternator:
          </label>
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{generatorSerial}</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"generatorSerial"} className={"form-control"}
                     autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                     value={generatorSerial} onChange={onGeneratorSerialChanged} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors['generatorSerial']} />
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{gensetModel}</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"generatorModel"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={gensetModel} onChange={onGensetModelChanged} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors['gensetModel']} />
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>{manufacturers[generator]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"generator"} name={"generator"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={generator} onChange={onGeneratorChanged}>
              <option value={manufacturers.AKSA}>{manufacturers[manufacturers.AKSA]}</option>
            </select>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={alternatorSerial} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"alternatorSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={alternatorSerial} onChange={onAlternatorSerialChanged} />
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={alternatorModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"alternatorModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={alternatorModel} onChange={onAlternatorModelChanged} />
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>{alternators[alternator]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"alternator"} name={"alternator"} className={"form-control form-select"} disabled={isLoading || isSubmitting}
                    value={alternator} onChange={onAlternatorChanged}>
              <option value={alternators.unspecified}>{alternators[alternators.unspecified]}</option>
              <option value={alternators.AKSA}>{alternators[alternators.AKSA]}</option>
              <option value={alternators.Stamford}>{alternators[alternators.Stamford]}</option>
            </select>
          }
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machineSerial"}>
            Machine Serial Number:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machineModel"}>
            Machine Model:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machine"}>
            Machine:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controllerSerial"}>
            Controller Serial Number:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controllerModel"}>
            Controller Model:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controller"}>
            Controller:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={machineSerial} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"machineSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={machineSerial} onChange={onMachineSerialChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={machineModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"machineModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={machineModel} onChange={onMachineModelChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>{machines[machine]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"machine"} name={"machine"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={machine} onChange={onMachineChanged}>
              <option value={machines.unspecified}>{machines[machines.unspecified]}</option>
              <option value={machines.AKSA}>{machines[machines.AKSA]}</option>
              <option value={machines.Cummins}>{machines[machines.Cummins]}</option>
              <option value={machines.Mitsubishi}>{machines[machines.Mitsubishi]}</option>
              <option value={machines.Perkins}>{machines[machines.Perkins]}</option>
            </select>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={controllerSerial} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"controllerSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={controllerSerial} onChange={onControllerSerialChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={controllerModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"controllerModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={controllerModel} onChange={onControllerModelChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>{controllers[controller]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"controller"} name={"controller"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={controller} onChange={onControllerChanged}>
              <option value={controllers.unspecified}>{controllers[controllers.unspecified]}</option>
              <option value={controllers.deepSea}>{controllers[controllers.deepSea]}</option>
            </select>
          }
        </div>

      </div>
    </div>
  )
  //#endregion
}
