import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { ListViewSettingsModal } from "./ListViewSettingsModal";

import './TablePagination.css';

export const TablePagination = ({
                                   currentPage,
                                   totalPages,
                                   totalRecords,
                                   onPageClicked,
                                   onPrevPageClicked,
                                   onNextPageClicked,
                                   isLoading,
                                   viewSettingsNamespace,
                                   onViewSettingsSaved,
                                 }) => {
  const [enableViewSettings, setEnableViewSettings] = useState(false);

  //#region Effects
  useEffect(() => {
    // TODO(yemon): Temp disabled the 'View Settings' feature for the time being
    //let _enableViewSettings = !!(viewSettingsNamespace && onViewSettingsSaved);
    //setEnableViewSettings(_enableViewSettings);
  }, []);
  //#endregion

  //#region Control handlers
  const gotoFirstPage = () => {
    gotoPage(1);
  }

  const gotoPrevPage = (fromPage) => {
    if (onPrevPageClicked !== undefined) onPrevPageClicked(fromPage);
  }

  const gotoPage = (page) => {
    if (onPageClicked !== undefined) onPageClicked(page);
  }

  const gotoNextPage = (fromPage) => {
    if (onNextPageClicked !== undefined) onNextPageClicked(fromPage);
  }

  const gotoLastPage = () => {
    if (totalPages === 0) return;
    gotoPage(totalPages);
  }

  const shouldDisablePrevArrows = () => {
    if (totalPages === 0) return true;
    return currentPage === 1;
  }

  const shouldDisableNextArrows = () => {
    if (totalPages === 0) return true;
    return currentPage === totalPages;
  }
  //#endregion

  //#region View Settings modal control handlers and others
  const [isViewSettingsModalOpen, setIsViewSettingsModalOpen] = useState(false);

  const viewSettingsModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsViewSettingsModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsViewSettingsModalOpen(false);
      }, 200);
    },
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className="table-pagination">
        <div className="pagination-settings">
          {enableViewSettings &&
            <button type={"button"} className={"btn btn-secondary"} disabled={isLoading}
                    onClick={viewSettingsModal.onOpenButtonClicked}>
              <i className="fa-solid fa-sliders"></i>
              <span>View settings...</span>
            </button>
          }
        </div>

        {enableViewSettings &&
          <ListViewSettingsModal isOpen={isViewSettingsModalOpen} onRequestClose={viewSettingsModal.onCloseButtonClicked}
                                 settingsNamespace={viewSettingsNamespace} onSettingsSaved={onViewSettingsSaved}
          />
        }

        <div className="pagination-summary">
          Page: {currentPage} of {totalPages} &bull; <b>Total record{totalRecords > 1 ? 's' : ''}: {totalRecords}</b>
        </div>
        <div className="pagination-controls">
          <div className="first-arrow">
            <button onClick={gotoFirstPage} disabled={shouldDisablePrevArrows()}>
              <i className="fa-solid fa-angles-left"></i>
            </button>
          </div>
          <div className="prev-arrow">
            <button onClick={ev => gotoPrevPage(currentPage)} disabled={shouldDisablePrevArrows()}>
              <i className="fa-solid fa-angle-left"></i>
            </button>
          </div>
          <div className="page-number">
            <select id={"page"} name={"page"} className={"page-number-dropdown"} disabled={totalPages === 0}
                    value={currentPage} onChange={(ev) => gotoPage(parseInt(ev.target.value))}>
              {totalPages > 0 ?
                [...Array(totalPages).keys()].map(pageIndex =>
                  <option key={pageIndex} value={pageIndex + 1}>{pageIndex + 1}</option>
                )
                : <option key={0} value={0}>-</option>
              }
            </select>
          </div>
          <div className="next-arrow">
            <button onClick={(ev) => gotoNextPage(currentPage)} disabled={shouldDisableNextArrows()}>
              <i className="fa-solid fa-angle-right"></i>
            </button>
          </div>
          <div className="last-arrow">
            <button onClick={gotoLastPage} disabled={shouldDisableNextArrows()}>
              <i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  )
  //#endregion
}
