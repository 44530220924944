import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";
import { viewSettings } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { ActorNameDisplay } from "../shared/ActorNameDisplay";
import { JobStatusBadge } from "./JobStatusBadge";
import { SortIndicator } from "../shared/SortIndicator";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
// TODO(yemon): Style definitions in these two files need proper refactoring.
import "./ArrivalInspectionEntryPage.css";
import "./StockInspectionEntryPage.css";


export function ServiceGeneratorPage() {
  //#region States
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedGeneratorId, setSelectedGeneratorId] = useState(null);

  const [isLoadingGenerator, setIsLoadingGenerator] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [gensetModel, setGensetModel] = useState("");
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [manufacturer, setManufacturer] = useState(refs.inventory.generatorManufacturers.AKSA);
  const [alternatorSerial, setAlternatorSerial] = useState("");
  const [alternatorModel, setAlternatorModel] = useState("");
  const [alternator, setAlternator] = useState(refs.inventory.generatorAlternators.unspecified);
  const [machineSerial, setMachineSerial] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machine, setMachine] = useState(refs.inventory.generatorMachines.unspecified);
  const [controllerSerial, setControllerSerial] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [controller, setController] = useState(refs.inventory.generatorControllers.unspecified);

  const [warrantyHours, setWarrantyHours] = useState(null);
  const [warrantyMonths, setWarrantyMonths] = useState(null);
  const [warrantyStatus, setWarrantyStatus] = useState(false);

  const [isLoadingJobHistory, setIsLoadingJobHistory] = useState(false);
  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [selectedCommissionId, setSelectedCommissionId] = useState(null);
  const [serviceJobHistory, setServiceJobHistory] = useState([]);
  const [pagination, setPagination] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.dashboard.url);
    }
    let _customerId = state['customerId'];
    let _profileId = state['profileId'];
    let _generatorId = state['generatorId'];
    if (!_customerId || !_profileId) {
      navigate(routes.dashboard.url);
    }
    else if (!_generatorId) {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': _customerId,
          'serviceProfileId': _profileId,
        }
      });
    }
    else {
      setSelectedCustomerId(_customerId);
      setSelectedProfileId(_profileId);
      setSelectedGeneratorId(_generatorId);
      fetchServiceGenerator(_profileId, _generatorId);
    }
  }, []);

  const fetchServiceGenerator = (profileId, generatorId) => {
    let params = {
      'uid': auth.getUserId(),
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
    };
    setIsLoadingGenerator(true);
    inventoryServices.fetchServiceProfileGenerator(params)
      .then((response) => {
        let _serviceGenerator = response['data'];
        let _serviceProfile = _serviceGenerator['serviceLocation']['profile'];
        setSelectedCustomerId(_serviceProfile['customer']['id']);
        setSelectedProfileId(_serviceProfile['id']);
        setSelectedGeneratorId(_serviceGenerator['id'])

        setServiceGenerator(_serviceGenerator);
        prepareProfileAndGeneratorDetails(_serviceGenerator);

        let _currentCommissionId = _serviceGenerator['commission']['id'];
        setSelectedCommissionId(_currentCommissionId);
        fetchJobHistory(profileId, generatorId, _currentCommissionId, 1);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.jobHistories.url);
          }
        }
      })
      .finally(() => {
        setIsLoadingGenerator(false);
      })
  }

  const prepareProfileAndGeneratorDetails = (serviceGenerator) => {
    if (!serviceGenerator) {
      return;
    }
    let _serviceLocation = serviceGenerator['serviceLocation'];
    let _serviceProfile = _serviceLocation['profile'];
    let _stock = serviceGenerator['stock'];

    setCustomerName(_serviceProfile['customer']['customerName']);
    setContactFullName(_serviceLocation['contactFullName']);
    setContactNo(_serviceLocation['contactNo']);

    setGeneratorSerial(_stock['generatorSerial']);
    setGensetModel(_stock['generator']['gensetModel']);
    setManufacturer(_stock['manufacturer']);
    setAlternatorSerial(_stock['alternatorSerial']);
    setAlternatorModel(_stock['alternatorModel']);
    setAlternator(_stock['alternator']);
    setMachineSerial(_stock['machineSerial']);
    setMachineModel(_stock['machineModel']);
    setMachine(_stock['machine']);
    setControllerSerial(_stock['controllerSerial']);
    setControllerModel(_stock['controllerModel']);
    setController(_stock['controller']);

    setWarrantyStatus(refs.inventory.serviceGeneratorWarrantyStatus[serviceGenerator['warrantyStatus']]);
    const commission = serviceGenerator['commission'];
    setWarrantyHours(commission['warrantyHours']);
    setWarrantyMonths(commission['warrantyMonths']);
  }

  const getWarrantySpecifications = () => {
    let specs = [];
    if (warrantyHours) {
      specs.push(`${warrantyHours} hours`);
    }
    if (warrantyMonths) {
      let label = "month";
      if (warrantyMonths > 1) {
        label += "s";
      }
      specs.push(`${warrantyMonths} ${label}`);
    }
    return specs.join(", ");
  }

  const prepareListPayload = (page) => {
    //let settings = getViewSettings(viewSettings.salesInquiries);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        //'pageSize': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
        'page_size': DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchJobHistory = (serviceProfileId, serviceGeneratorId, currentCommissionId, page) => {
    resetListingStates();
    setIsLoadingJobHistory(true);

    let payload = prepareListPayload(page);
    payload['service_profile_id'] = serviceProfileId;
    payload['service_generator_id'] = serviceGeneratorId;
    payload['commission_id'] = currentCommissionId;
    inventoryServices.fetchServiceGeneratorJobHistory(payload)
      .then((response) => {
        updateListingStates(response['data']);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
      })
      .finally(() => {
        setIsLoadingJobHistory(false);
      })
  }

  const updateListingStates = (responseData) => {
    setServiceJobHistory(responseData['data']);
    setPagination(responseData['pagination']);
    // TODO(yemon): Update listing context
  }

  const resetListingStates = () => {
    setServiceJobHistory([]);
    setPagination(null);
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': selectedCustomerId,
          'serviceProfileId': selectedProfileId,
        }
      });
    }, 200);
  }

  const onReturnClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': selectedCustomerId,
          'serviceProfileId': selectedProfileId,
        }
      });
    }, 200);
  }

  const onRefreshClicked = (ev) => {
    fetchJobHistory(selectedProfileId, selectedGeneratorId, 1);
  }

  const onJobHistoryRowClicked = (ev, serviceJob) => {
    ev.preventDefault();

    const historyTypes = refs.inventory.serviceHistoryType;
    const serviceTypes = refs.inventory.serviceType;
    const careContactType = refs.customer.contactType;

    let historyType = serviceJob['historyType'];
    let serviceType = serviceJob['serviceType'];
    let serviceGeneratorId = serviceJob['serviceGeneratorId'];
    let serviceHistoryId = serviceJob['id'];

    if (historyType === historyTypes.serviceHistory) {
      switch (serviceType) {
        case serviceTypes.tnc:
          return navigateToServiceHistoryEntry(routes.testingAndCommission.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.pm:
          return navigateToServiceHistoryEntry(routes.preventiveMaintenance.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.regular:
          return navigateToServiceHistoryEntry(routes.regularService.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.repair:
          return navigateToServiceHistoryEntry(routes.repairService.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.emergency:
          return navigateToServiceHistoryEntry(routes.emergencyBreakdown.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.inspection:
          return navigateToServiceHistoryEntry(routes.inspection.url, serviceGeneratorId, serviceHistoryId);
        default:
          break;
      }
    }
    else if (historyType === historyTypes.contactHistory) {
      return navigateToCareContactEntry(routes.careContactLogEntry.url, serviceGeneratorId, serviceHistoryId);
    }
  }

  const navigateToServiceHistoryEntry = (url, serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': selectedProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const navigateToCareContactEntry = (url, serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': selectedProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const onPageClick = (page) => {
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }
  //#endregion

  //#region View Settings modal and view context related
  const onViewSettingsSaved = () => {
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, 1);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {routes.serviceProfile.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.serviceGenerator.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>[{gensetModel}] {generatorSerial}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Contact Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"} title={"Location name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div title={"Location address"}>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Commission/Installation Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Warranty Specification:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Warranty Status:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {serviceGenerator &&
                    <Moment date={serviceGenerator['installationDate']} format={formatters.datetimeShort} />
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {getWarrantySpecifications()}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {warrantyStatus}
                </ReadonlyField>
              </div>

            </div>
          </div>

          <ServiceEntryGenerator gensetModel={gensetModel} onGensetModelChanged={null}
                                 generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                 generator={manufacturer} onGeneratorChanged={null}
                                 alternatorSerial={alternatorSerial} onAlternatorSerialChanged={null}
                                 alternatorModel={alternatorModel} onAlternatorModelChanged={null}
                                 alternator={alternator} onAlternatorChanged={null}
                                 machineSerial={machineSerial} onMachineSerialChanged={null}
                                 machineModel={machineModel} onMachineModelChanged={null}
                                 machine={machine} onMachineChanged={null}
                                 controllerSerial={controllerSerial} onControllerSerialChanged={null}
                                 controllerModel={controllerModel} onControllerModelChanged={null}
                                 controller={controller} onControllerChanged={null}
                                 isLoading={isLoadingGenerator} isEditable={false}
                                 isSubmitting={false} hasErrors={false} formErrors={null}
          />

          <div className={"form-section"}>
            <h2>Job History</h2>

            <div className={"form-section-controls"}>
              <button type={"button"} className={"btn btn-secondary"} disabled={isLoadingGenerator}
                      onClick={onReturnClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Profile
              </button>
              <button className={"btn btn-secondary right-margin"} disabled={isLoadingGenerator}
                      onClick={onRefreshClicked}>
                {isLoadingGenerator && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoadingGenerator && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>
                  Date
                  <SortIndicator isAscending={false} />
                </th>
                <th scope={"col"}>History Type</th>
                <th scope={"col"}>Service/Contact Type</th>
                <th scope={"col"}>Submitted By</th>
                <th scope={"col"}>Status</th>
              </tr>
              </thead>
              <tbody>
              {isLoadingGenerator && <TableLoadingIndicator colspan={6} /> }

              {serviceJobHistory && serviceJobHistory.length > 0 && !isLoadingJobHistory &&
                serviceJobHistory.map((serviceJob, index) =>
                  <JobHistoryRow serviceJob={serviceJob} index={index} key={serviceJob['id']}
                                 currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                 onRowClicked={onJobHistoryRowClicked}
                  />
                )
              }

              {!serviceJobHistory || (serviceJobHistory.length === 0 && !isLoadingGenerator &&
                  <TableEmptyRow colSpan={6} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoadingGenerator}
                               viewSettingsNamespace={viewSettings.serviceGeneratorJobHistory}
                               onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const careContactTypes = refs.customer.contactType;

const JobHistoryRow = ({
                         serviceJob, index,
                         currentPage, pageSize,
                         onRowClicked,
                       }) => {
  const isFollowupContactEntry = () => {
    if (!serviceJob) {
      return false;
    }
    if (serviceJob['historyType'] !== serviceHistoryTypes.contactHistory) {
      return false;
    }
    let _careContact = serviceJob['careContact'];
    return _careContact !== null && _careContact['followupServiceHistoryId'] !== null;
  }

  return (
    <tr>
      <td className={"index-col"}>{(pageSize * (currentPage - 1)) + (index + 1)}</td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
          onClick={(ev) => onRowClicked(ev, serviceJob)}>
          <Moment date={serviceJob['servicedDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        {serviceHistoryTypes[serviceJob['historyType']]}
      </td>
      <td>
        {serviceJob['historyType'] === serviceHistoryTypes.serviceHistory &&
          <span>{serviceTypes[serviceJob['serviceType']]}</span>
        }
        {serviceJob['historyType'] === serviceHistoryTypes.contactHistory &&
          <span>{careContactTypes[serviceJob['careContactType']]}</span>
        }
        {isFollowupContactEntry() &&
          <i className="fa-solid fa-link" style={{ color: 'gray', fontSize: '100%', marginLeft: '8px' }}></i>
        }
      </td>
      <td>
        <ActorNameDisplay employee={serviceJob['servicedBy']} />
      </td>
      <td>
        <JobStatusBadge status={serviceJob['status']} />
      </td>
    </tr>
  )
}
