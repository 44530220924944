import React from "react";

export const NullBlankField = ({ record, fieldName }) => {
  return (
    <>
      {record[fieldName] !== null && record[fieldName] !== '' ? record[fieldName] : '-'}
    </>
  )
}

export const NullBlankValue = ({ value }) => {
  return (
    <>
      {value !== null && value !== '' ? value : '-'}
    </>
  )
}
