import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { refs } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { authPortalServices } from "../../repos/apiServices";
import { portalServices } from "../../repos/apiServices";

import { Footer } from "../shared/Footer";

import './LoginPage.css';

export const LoginPage = () => {
  //#region States
  const [loginForm, setLoginForm] = useState({ username: '', password: '' });
  const [errors, setErrors] = useState({ hasErrors: false, usernameError: '', passwordError: '', loginError: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState({ password: '', confirmPassword: '' });
  const [passwordErrors, setPasswordErrors] = useState({ hasErrors: false, errors: [] });
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);

  const auth = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  if (auth.hasUserSession()) {
    return <Navigate to='/' state={{ path: location.pathname }} />
  }
  //#endregion

  //#region Control handlers
  const onUsernameChanged = (ev) => {
    setLoginForm({ ...loginForm, username: ev.target.value });
  }

  const onPasswordChanged = (ev) => {
    setLoginForm({ ...loginForm, password: ev.target.value });
  }

  const onLoginSubmitted = (ev) => {
    ev.preventDefault();
    setIsSubmitting(true);
    let errors = {
      hasErrors: false,
      usernameError: '',
      passwordError: '',
      loginError: '',
    }
    setErrors(errors);
    if (loginForm.username.trim() === '') {
      errors.hasErrors = true;
      errors.usernameError = 'Login username is required.';
    }
    else if (loginForm.username.trim().length < 4) {
      errors.hasErrors = true;
      errors.usernameError = 'Login username has to be at least 4 characters long.';
    }
    if (loginForm.password.trim() === '') {
      errors.hasErrors = true;
      errors.passwordError = 'Password is required.';
    }
    else if (loginForm.password.trim().length < 8) {
      errors.hasErrors = true;
      errors.passwordError = 'Password has to be at least 8 characters long.';
    }

    if (errors.hasErrors) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    let _authToken = null;
    authService.tokenizedLogin({ username: loginForm.username, password: loginForm.password })
      .then((response) => {
        _authToken = response.data;

        return authPortalServices.fetchUserInfo(loginForm.username, _authToken.access);
      })
      .then((response) => {
        let _userInfo = response.data;
        setUserInfo(_userInfo);
        if (_userInfo['status'] === refs.portal.accountStatus.passwordReset) {
          setIsChangingPassword(true);
        }
        else {
          login(_authToken, _userInfo);
        }
      })
      .catch((error) => {
        errors.hasErrors = true;
        auth.logout();
        if (error.response) {
          let _responseStatus = error.response.status;
          let _responseData = error.response.data;
          if (_responseStatus === 400) {
            errors.loginError = _responseData['error'];
          }
          if (_responseStatus === 401) {
            errors.loginError = 'There is no account registered with these login credentials.';
          }
        } else {
          errors.loginError = 'There was a problem trying to reach the server.';
        }
        setErrors(errors);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const login = (authToken, userInfo) => {
    auth.login(authToken);
    auth.saveUserInfoWithRouteGrants(userInfo);
    navigate('/');
  }

  const onNewPasswordChanged = (ev) => {
    setNewPassword({ ...newPassword, password: ev.target.value });
  }

  const onConfirmPasswordChanged = (ev) => {
    setNewPassword({ ...newPassword, confirmPassword: ev.target.value });
  }

  const containsSpace = (password) => {
    for (const char of password) {
      if (char === ' ') {
        return true;
      }
    }
    return false;
  }

  const isConformPasswordStandard = (password) => {
    // !@#$%^&*()-_
    const _specialChars = [33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 45, 95];

    let containsAlpha = false;
    let containsNumbers = false;
    for (let idx = 0; idx < password.length; idx+=1) {
      const asciiCode = password.charCodeAt(idx);
      // A-Z, a-z
      if ((asciiCode >= 65 && asciiCode <= 90) || (asciiCode >= 97 && asciiCode <= 122)) {
        containsAlpha = true;
        continue;
      }
      // 0-9
      if (asciiCode >= 48 && asciiCode <= 57) {
        containsNumbers = true;
      }
    }
    return containsAlpha && containsNumbers;
  }

  const onSubmitPasswordChange = (ev) => {
    ev.preventDefault();
    setIsSubmittingPassword(true);
    let _passwordErrors = { ...passwordErrors };
    _passwordErrors.hasErrors = false;
    _passwordErrors.errors = [];

    const _password = newPassword.password.trim();
    const _newPassword = newPassword.confirmPassword.trim();
    if (_password.trim() === '' || _newPassword.trim() === '') {
      _passwordErrors.hasErrors = true;
      _passwordErrors.errors.push('Both password fields are required.');
    }
    else if (_password.trim().length < 8 || _newPassword.trim().length < 8) {
      _passwordErrors.hasErrors = true;
      _passwordErrors.errors.push('Both passwords have to be at least 8 characters long.');
    }
    else if (_password.trim() !== _newPassword.trim()) {
      _passwordErrors.hasErrors = true;
      _passwordErrors.errors.push('Both the password and the confirm password has to be exactly the same.');
    }
    else if (containsSpace(_newPassword)) {
      _passwordErrors.hasErrors = true;
      _passwordErrors.errors.push('The password contains at least one space character " ". Please remove it.');
    }
    else if (!isConformPasswordStandard(_newPassword)) {
      _passwordErrors.hasErrors = true;
      _passwordErrors.errors.push('The new password has to confirm with the rules as stated.');
    }

    if (_passwordErrors.hasErrors) {
      setPasswordErrors(_passwordErrors);
      setIsSubmittingPassword(false);
      return;
    }

    setPasswordErrors(_passwordErrors);
    let _authToken = null;

    let payload = {
      'user_id': userInfo['userId'],
      'service_profile_id': userInfo['serviceProfileId'],
      'id': userInfo['id'],                                 // PortalUser.id
      'new_password': newPassword.password,
    };
    portalServices.changePortalUserPassword(payload)
      .then((response) => {
        console.log(response);
        let _responseData = response['data'];

        if (_responseData['hasErrors']) {
          _passwordErrors.hasErrors = true;
          _passwordErrors.errors = [_responseData['message']];
          setPasswordErrors(_passwordErrors);
        }
        else {
          return authService.tokenizedLogin({ username: loginForm.username, password: newPassword.password });
        }
      })
      .then((response) => {
        _authToken = response.data;
        return authPortalServices.fetchUserInfo(loginForm.username, _authToken.access);
      })
      .then((response) => {
        let _userInfo = response.data;
        setUserInfo(_userInfo);         // NOTE(yemon): Not totally necessary, but it's just there for parody's sake.
        login(_authToken, _userInfo);
      })
      .finally(() => {
        setIsSubmittingPassword(false);
      });
  }
  //#endregion

  //#region Render
  return (
    <main className="login-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/login-background.png` }}>
      {!isChangingPassword &&
        <LoginForm loginForm={loginForm}
                   onUsernameChanged={onUsernameChanged}
                   onPasswordChanged={onPasswordChanged}
                   errors={errors}
                   isSubmitting={isSubmitting}
                   onLoginSubmitted={onLoginSubmitted} />
      }
      {isChangingPassword &&
        <ChangePasswordForm userInfo={userInfo}
                            newPassword={newPassword}
                            onPasswordChanged={onNewPasswordChanged}
                            onConfirmPasswordChanged={onConfirmPasswordChanged}
                            passwordErrors={passwordErrors}
                            isSubmittingPassword={isSubmittingPassword}
                            onSubmitPasswordChange={onSubmitPasswordChange} />
      }

      <Footer fullWidth={true} />
    </main>
  )
  //#endregion

}


const LoginForm = ({
                     loginForm,
                     onUsernameChanged,
                     onPasswordChanged,
                     errors,
                     isSubmitting,
                     onLoginSubmitted,
                   }) => {
  return (
    <div className="login-form-container">
      <div className="login-form-left"></div>
      <div className="login-form">
        <h1>LOGIN</h1>
        <div className="info-message">
          Enter your credentials below:
        </div>
        <form onSubmit={onLoginSubmitted} autoComplete={"off"}>
          <div className="form-field">
            <input type="textbox" className="form-control" id="username" name="username" placeholder="Login username"
                   autoComplete={"off"} autoFocus minLength={4}
                   value={loginForm.username} onChange={onUsernameChanged} />
          </div>
          <div className="form-field">
            <input type="password" className="form-control" id="password" name="password" placeholder="Password"
                   autoComplete={"off"} minLength={4}
                   value={loginForm.password} onChange={onPasswordChanged} />
          </div>
          {errors.hasErrors &&
            <div className="error-message validation-message">
              {errors.usernameError && <div>{errors.usernameError}</div>}
              {errors.passwordError && <div>{errors.passwordError}</div>}
              {errors.loginError && <div>{errors.loginError}</div>}
            </div>
          }
          <div>
            <button type="submit" className="btn btn-primary btn-lg w-100 login-button" name="submit"
                    disabled={isSubmitting}>
              {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isSubmitting && <i className="fa-solid fa-lock"></i>}
              <span>Login</span>
            </button>
          </div>
        </form>
      </div>
      <div className="login-form-right"></div>
    </div>
  )
}


const ChangePasswordForm = ({
                              userInfo,
                              newPassword,
                              onPasswordChanged,
                              onConfirmPasswordChanged,
                              passwordErrors,
                              isSubmittingPassword,
                              onSubmitPasswordChange,
                            }) => {
  return (
    <div className={"change-password-form-container"}>
      <div className={"change-password-frame"}>
        <div className={"change-password-info hide-mobile"}>
          <ChangePasswordInfo userInfo={userInfo} />
        </div>

        <div className={"change-password-form"}>
          <h1>SET PASSWORD</h1>
          <div className="info-message">
            Enter your <b>new password</b> below before logging in to the Portal for the first time:
          </div>
          <form onSubmit={onSubmitPasswordChange} autoComplete={"off"}>
            <div className={"form-field"}>
              <input type={"password"} className={"form-control"} id={"password"} name={"password"}
                     placeholder={"Your new password"} minLength={4} autoComplete={"off"} autoFocus
                     value={newPassword.password} onChange={onPasswordChanged} />
            </div>
            <div className={"form-field"}>
              <input type={"password"} className={"form-control"} id={"confirm-password"} name={"confirmPassword"}
                     placeholder={"Confirm your new password"} minLength={4} autoComplete={"off"}
                     value={newPassword.confirmPassword} onChange={onConfirmPasswordChanged} />
            </div>
            {passwordErrors.hasErrors &&
              <div className={"error-message validation-message"}>
                {passwordErrors.errors.map((errorMessage, index) =>
                  <div key={index}>{errorMessage}</div>
                )}
              </div>
            }
            <div>
              <button type="submit" className="btn btn-primary btn-lg w-100 login-button" name="submit"
                      disabled={isSubmittingPassword}>
                {isSubmittingPassword && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingPassword && <i className="fa-solid fa-lock"></i>}
                <span>Save and Login</span>
              </button>
            </div>
          </form>
        </div>

        <div className={"change-password-info mobile-info show-mobile"}>
          <ChangePasswordInfo userInfo={userInfo} />
        </div>
      </div>
    </div>
  )
}


const ChangePasswordInfo = ({ userInfo }) => {
  return (
    <div>
      <p>
        You are now logging in to the PTI Customer Portal as <b>{userInfo['displayFullname']}</b>, registered under the customer profile of <b>{userInfo['customerName']}</b>.
      </p>
      <p>
        You need to specify a password of your own before gaining further access. Your password:
      </p>
      <ul>
        <li>Must contain at least 8 characters.</li>
        <li>Must contain a mixture of characters and numbers.</li>
        {/*This is contrived, and not being checked at all. Commenting out for now util it's a reality.*/}
        {/*<li>Must not be a commonly used password.</li>*/}
        <li>Must <i>not</i> contain spaces " ".</li>
      </ul>
      <p>
        Please <i>do not share</i> your password with anyone else. PTI personals <i>will never ask</i> you for your password during support as well.
      </p>
      <p>
        Contact PTI if you need any assistance.
      </p>
    </div>
  )
}
