import React from "react";
import { useState } from "react";

import { refs } from "../../repos/constants";
import { NullBlankValue } from "./NullBlankField";
import { ReadonlyField } from "../shared/ReadonlyField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";

import "../shared/DataTable.css";

export const ServiceEntryTestResultsList = ({
                                              isFormReadonly, isFormDisabled,
                                              groupType, setGroupType,
                                              runningHours, setRunningHours,
                                              isLoading, testResults,
                                            }) => {
  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
          <div className={"form-label"}>
            <label>
              Group Type:
            </label>
          </div>
          <div className={"form-label"}>
            <label>
              Running Hours:
            </label>
          </div>

          <div className={"form-input"}>
            {isFormReadonly && <ReadonlyField>{refs.inventory.generatorType[groupType]}</ReadonlyField>}
            {/*TODO(yemon): Render text inputs for non-read only modes.*/}
          </div>
          <div className={"form-input"}>
            {isFormReadonly && <ReadonlyField><NullBlankValue value={runningHours} /></ReadonlyField>}
          </div>
        </div>
      </div>

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <TestResultsTableHeading />

            <tbody>
            {isLoading && <TableLoadingIndicator colspan={15} />}

            {!isLoading && testResults && testResults.length > 0 &&
              testResults.map((testResult, index) =>
                <TestResultsTableRow key={testResult['id']} testResult={testResult} index={index}
                                     onRowClicked={null}
                />
              )
            }

            {!isLoading && (!testResults || (testResults.length === 0)) &&
              <TableEmptyRow colSpan={15} />
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
  //#endregion
}

const TestResultsTableHeading = () => {
  return (
    <thead>
    <tr>
      <th scope={"col"} style={{ minWidth: 14 }}></th>
      <th scope={"col"}>Time</th>
      <th scope={"col"}>Load</th>
      <th scope={"col"}>Ambient Temp.</th>
      <th scope={"col"}>Water Temp.</th>
      <th scope={"col"}>Oil Press.</th>
      <th scope={"col"} colSpan={3}>Current (A)</th>
      <th scope={"col"} colSpan={3}>Volts</th>
      <th scope={"col"}>Frequency</th>
      <th scope={"col"}>Power</th>
      <th scope={"col"}>Power Factor</th>
    </tr>
    <tr>
      <th scope={"col"}></th>
      <th scope={"col"}>(Min)</th>
      <th scope={"col"}>(%)</th>
        <th scope={"col"}>(C)</th>
        <th scope={"col"}>(C)</th>
        <th scope={"col"}>(Bar)</th>
        <th scope={"col"}>R (U)</th>
        <th scope={"col"}>Y (V)</th>
        <th scope={"col"}>B (W)</th>
        <th scope={"col"}>PH1 (V)</th>
        <th scope={"col"}>PH2 (V)</th>
        <th scope={"col"}>PH3 (V)</th>
        <th scope={"col"}>(Hz)</th>
        <th scope={"col"}>(KW)</th>
        <th scope={"col"}>(cos &empty;)</th>
      </tr>
    </thead>
  )
}

const TestResultsTableRow = ({
                               testResult, index,
                               onRowClicked,
                             }) => {
  return (
    <tr>
      <td></td>
      <td>
        <NullBlankValue value={testResult['time']} />
      </td>
      <td>
        <NullBlankValue value={testResult['load']} />
      </td>
      <td>
        <NullBlankValue value={testResult['ambientTemperature']} />
      </td>
      <td>
        <NullBlankValue value={testResult['waterTemperature']} />
      </td>
      <td>
        <NullBlankValue value={testResult['oilPressure']} />
      </td>
      <td>
        <NullBlankValue value={testResult['currentR']} />
      </td>
      <td>
        <NullBlankValue value={testResult['currentY']} />
      </td>
      <td>
        <NullBlankValue value={testResult['currentB']} />
      </td>
      <td>
        <NullBlankValue value={testResult['voltagePh1']} />
      </td>
      <td>
        <NullBlankValue value={testResult['voltagePh2']} />
      </td>
      <td>
        <NullBlankValue value={testResult['voltagePh3']} />
      </td>
      <td>
        <NullBlankValue value={testResult['frequency']} />
      </td>
      <td>
        <NullBlankValue value={testResult['power']} />
      </td>
      <td>
        <NullBlankValue value={testResult['powerFactor']} />
      </td>
    </tr>
  )
}
