import React from "react";

import { JobStatusBadge } from "./JobStatusBadge";
import { ActorNameRankDisplay } from "../shared/ActorNameDisplay";
import { ReadonlyField } from "../shared/ReadonlyField";

export const ServiceEntrySignature = ({
                                        jobStatus,
                                        testedBy,
                                      }) => {
  return (
    <div className={"form-section"}>
      <h2>Form Signatures</h2>

      {/* TODO(yemon): Standardize that 'gridAutoFlow' property */}
      <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
        <div className={"form-label"}>
          <label htmlFor={"testedBy"}>
            Status:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"testedBy"}>
            Tested/Submitted By:
          </label>
        </div>

        <div className={"form-input"}>
          <div className={"readonly-field"}>
            <JobStatusBadge status={jobStatus} />
          </div>
        </div>

        <div className={"form-input"}>
          <ReadonlyField>
            <ActorNameRankDisplay employee={testedBy} />
          </ReadonlyField>
        </div>

      </div>
    </div>
  )
}
