import React from "react";
import ReactModal from "react-modal";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";

import { navigableRoutes as routes } from "./repos/constants";

import { AuthProvider } from "./components/auth/AuthProvider";
import { RequireAuth } from "./components/auth/RequireAuth";
import { NotFound } from "./components/shared/NotFound";

import { Header } from "./components/shared/Header";
import { LoginPage } from "./components/auth/LoginPage";
import { DashboardPage } from "./components/dashboard/DashboardPage";

import { ServiceProfilePage } from "./components/service/ServiceProfilePage";
import { ServiceGeneratorPage } from "./components/service/ServiceGeneratorPage";
import { TestingAndCommissionEntryPage } from "./components/service/TestingAndCommissionEntryPage";
import { PreventiveMaintenanceEntryPage } from "./components/service/PreventiveMaintenanceEntryPage";
import { RegularEntryPage } from "./components/service/RegularEntryPage";
import { RepairEntryPage } from "./components/service/RepairEntryPage";
import { EmergencyBreakdownEntryPage } from "./components/service/EmergencyBreakdownEntryPage";
import { InspectionEntryPage } from "./components/service/InspectionEntryPage";
import { ContactLogEntryPage } from "./components/customer/ContactLogEntryPage";

// import { SalesInquiryListPage } from "./components/sales/SalesInquiryListPage";
// import { SalesInquiryEntryPage } from "./components/sales/SalesInquiryEntryPage";
// import { QuotationRequestListPage } from "./components/quotation/QuotationRequestListPage";
// import { QuotationRequestEntryPage } from "./components/quotation/QuotationRequestEntryPage";
// import { OrderConfirmationListPage } from "./components/order/OrderConfirmationListPage";
// import { OrderConfirmationEntryPage } from "./components/order/OrderConfirmationEntryPage";
// import { PaymentBreakdownPage } from "./components/order/PaymentBreakdownPage";

// import { StocksListPage } from "./components/service/StocksListPage";
// import { StockDetailsPage } from "./components/service/StockDetailsPage";
// import { ArrivalInspectionEntryPage } from "./components/service/ArrivalInspectionEntryPage";
// import { StockInspectionEntryPage } from "./components/service/StockInspectionEntryPage";
// import { JobHistoriesListPage } from "./components/service/JobHistoriesListPage";
// import { NewServiceProfilePage } from "./components/service/NewServiceProfilePage";
// import { ServiceProfilePage } from "./components/service/ServiceProfilePage";
// import { NewCommissionEntryPage } from "./components/service/NewCommissionEntryPage";
// import { ServiceGeneratorPage } from "./components/service/ServiceGeneratorPage";
// import { TestingAndCommissionEntryPage } from "./components/service/TestingAndCommissionEntryPage";
// import { PreventiveMaintenanceEntryPage } from "./components/service/PreventiveMaintenanceEntryPage";

// import { CustomerListPage } from "./components/customer/CustomerListPage";
// import { CustomerProfilePage } from "./components/customer/CustomerProfilePage";
// import { ContactLogEntryPage } from "./components/customer/ContactLogEntryPage";
// import { InboundCallsListPage } from "./components/customer/InboundCallsListPage";
// import { OutboundCallsListPage } from "./components/customer/OutboundCallsListPage";
// import { ComplaintsListPage } from "./components/customer/ComplaintsListPage";

import './App.css';


function App() {
  ReactModal.setAppElement('#root');

  return (
    <AuthProvider>
      <Header />
      <Routes>
        <Route path={routes.login.url} element={<LoginPage />}></Route>
        <Route path={routes.dashboard.url} element={<RequireAuth routeId={0}><DashboardPage /></RequireAuth>}></Route>

        <Route path={routes.serviceProfile.url} element={<RequireAuth routeId={routes.serviceProfile.id}><ServiceProfilePage /></RequireAuth> }></Route>
        <Route path={routes.serviceGenerator.url} element={<RequireAuth routeId={routes.serviceProfile.id}><ServiceGeneratorPage /></RequireAuth> }></Route>
        <Route path={routes.testingAndCommission.url} element={<RequireAuth routeId={routes.serviceProfile.id}><TestingAndCommissionEntryPage /></RequireAuth>}></Route>
        <Route path={routes.preventiveMaintenance.url} element={<RequireAuth routeId={routes.serviceProfile.id}><PreventiveMaintenanceEntryPage /></RequireAuth>}></Route>
        <Route path={routes.regularService.url} element={<RequireAuth routeId={routes.serviceProfile.id}><RegularEntryPage /></RequireAuth>}></Route>
        <Route path={routes.repairService.url} element={<RequireAuth routeId={routes.serviceProfile.id}><RepairEntryPage /></RequireAuth>}></Route>
        <Route path={routes.emergencyBreakdown.url} element={<RequireAuth routeId={routes.serviceProfile.id}><EmergencyBreakdownEntryPage /></RequireAuth>}></Route>
        <Route path={routes.inspection.url} element={<RequireAuth routeId={routes.serviceProfile.id}><InspectionEntryPage /></RequireAuth>}></Route>
        <Route path={routes.careContactLogEntry.url} element={<RequireAuth routeId={routes.serviceProfile.id}><ContactLogEntryPage /></RequireAuth>}></Route>

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
