import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { createContext } from "react";

import { authService } from "../../repos/apiServices";
import { navigableModules as modules } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  //#region States
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const keepAliveWorkerRef = useRef(null);
  //#endregion

  //#region Effects
  useEffect(() => { // Mount
    updateLoginStatus();
    updateUserInfo();
    if (hasUserSession()) {
      refreshToken();
      clearInterval(keepAliveWorkerRef.current);
      keepAliveWorkerRef.current = startKeepAliveWorker();
    }

    return () => {  // Unmount
      clearInterval(keepAliveWorkerRef.current);
      keepAliveWorkerRef.current = null;
    }
  }, []);
  //#endregion

  //#region Handlers
  const login = (tokenFromLogin) => {
    localStorage.setItem('token', tokenFromLogin.access);
    localStorage.setItem('refresh', tokenFromLogin.refresh);
    updateLoginStatus();
    clearInterval(keepAliveWorkerRef.current);
    keepAliveWorkerRef.current = startKeepAliveWorker();
  }

  const saveUserInfoWithRouteGrants = (userInfo) => {
    let user = {
      'displayFullname': userInfo.displayFullname,
      'customerName': userInfo.customerName,
    }
    setUserInfo(user);
    localStorage.setItem('user', JSON.stringify(user));

    let userIdentifier = {
      'id': userInfo['userId'],             // PortalUser.user.id
      'pid': userInfo['serviceProfileId'],  // PortalUser.profile.id
      'uid': userInfo['id'],                // PortalUser.id
      'cid': userInfo['customerId'],        // PortalUser.profile.customer.id
    }
    localStorage.setItem('uid', JSON.stringify(userIdentifier));

    // TODO(yemon): Route grants is not needed for the time being for portal.
    // let grants = {
    //   modules: userInfo['moduleGrants'],
    //   routes: userInfo['routeGrants'],
    // };
    let mockGrants = {
      modules: [modules.service.id],
      routes: [routes.serviceProfile.id],
    };
    localStorage.setItem('grants', JSON.stringify(mockGrants));
  }

  const updateLoginStatus = () => {
    setIsLoggedIn(hasUserSession);
  }

  const updateUserInfo = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    setUserInfo(user);
  }

  const hasUserSession = () => {
    return localStorage.getItem('token') != null && localStorage.getItem('refresh') != null;
  }

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user');
    localStorage.removeItem('uid');
    //localStorage.removeItem('grants');
    updateLoginStatus();
    clearInterval(keepAliveWorkerRef.current)
    keepAliveWorkerRef.current = null;
  }

  const startKeepAliveWorker = () => {
    const keepAliveIntervalMinutes = 1000 * 60 * 3;

    return setInterval(() => {
      refreshToken();
    }, keepAliveIntervalMinutes);
  }

  const refreshToken = () => {
    authService.tokenRefresh()
      .then((response) => {
        localStorage.setItem('token', response.data.access);
        updateLoginStatus();
      })
      .catch((error) => {
        console.error('AuthProvider keep alive error:', error);
        if (error.response) {
          if (error.response.status === 400) {
            // bad request
          }
        } else {
          // network error
        }
        logout();
      });
  }

  const getUserId = () => {
    return JSON.parse(localStorage.getItem('uid'));
  }

  const getUserGrants = () => {
    return JSON.parse(localStorage.getItem('grants'));
  }
  //#endregion

  //#region Render context provider
  return (
    <AuthContext.Provider value={{
      isLoggedIn, userInfo,
      hasUserSession, login, saveUserInfoWithRouteGrants, logout, refreshToken, getUserId, getUserGrants,
    }}>
      {children}
    </AuthContext.Provider>
  )
  //#endregion
}

export const useAuth = () => {
  return useContext(AuthContext);
}
