import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { navigableModules as modules } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import './Navigation.css';


export const Navigation = () => {
  const [moduleGrants, setModuleGrants] = useState(null);
  const [routeGrants, setRouteGrants] = useState(null);

  const auth = useAuth();

  useEffect(() => {
    // const grants = auth.getUserGrants();
    // setModuleGrants(grants['modules']);
    // setRouteGrants(grants['routes']);
  }, []);

  return (
    <nav id={"navbar"} className="side-nav">
      <Modules>
        <ModuleSection>
          <ModuleNavItem associatedRoutes={[routes.dashboard]}
                         route={routes.dashboard} />
        </ModuleSection>

        <ModuleSection module={{ name: "Service" }}>
          <ModuleNavItem associatedRoutes={[
            routes.serviceProfile, routes.serviceGenerator,
            routes.testingAndCommission, routes.preventiveMaintenance,
            routes.regularService, routes.repairService,
            routes.emergencyBreakdown, routes.inspection, routes.careContactLogEntry,
          ]} route={routes.serviceProfile} />
        </ModuleSection>

        {/*<AuthModuleSection module={modules.service} moduleGrants={moduleGrants}>*/}
        {/*  <AuthModuleNavItem associatedRoutes={[routes.serviceProfile]}*/}
        {/*                     route={routes.serviceProfile}*/}
        {/*                     routeGrants={routeGrants} />*/}
        {/*</AuthModuleSection>*/}
      </Modules>
    </nav>
  )
}


const Modules = ({ children }) => {
  return (
    <div className="modules">
      {children}
    </div>
  )
}

const ModuleSection = (props) => {
  const {
    module, children,
  } = props;

  return (
    <div className="module-section">
      {module &&
        <div className="module-header">
          {module.name.toUpperCase()}
        </div>
      }
      <div className="module-navbar">
        {children}
      </div>
    </div>
  )
}

const AuthModuleSection = (props) => {
  const {
    module, moduleGrants, children,
  } = props;

  const isModuleGranted = () => {
    if (!module || !moduleGrants) return false;
    return moduleGrants.includes(module.id);
  }

  return (
    <>
    {isModuleGranted() &&
      <div className="module-section">
        {module &&
          <div className="module-header">
            {module.name.toUpperCase()}
          </div>
        }
        <div className="module-navbar">
          {children}
        </div>
      </div>
    }
    </>
  )
}

/// Regular navigation menu item which doesn't need authorization.
const ModuleNavItem = (props) => {
  const {
    associatedRoutes, route,
  } = props;

  const location = useLocation();

  return (
    <>
      <div className={getNavLinkClass(location.pathname, associatedRoutes)}>
        <NavLink to={route.url}>
          <i className={`fa-solid ${route.faIcon}`}></i>
          <div className={"nav-item-label"}>{route.display}</div>
        </NavLink>
      </div>
      <div className={getNavLinkClass(location.pathname, associatedRoutes, true)}>
        <NavLink to={route.url}>
          <i className={`fa-solid ${route.faIcon}`}></i>
          <div className={"nav-item-label"}>{route.display}</div>
        </NavLink>
      </div>
    </>
  )
}

/// Authorized navigation menu item.
const AuthModuleNavItem = (props) => {
  const {
    associatedRoutes, route, routeGrants,
  } = props;

  const location = useLocation();

  const isRouteGranted = () => {
    if (!route || !routeGrants) return false;
    return routeGrants.includes(route.id);
  }

  return (
    <>
      {isRouteGranted() &&
        <>
          <div className={getNavLinkClass(location.pathname, associatedRoutes)}>
            <NavLink to={route.url}>
              <i className={`fa-solid ${route.faIcon}`}></i>
              <div className={"nav-item-label"}>{route.display}</div>
            </NavLink>
          </div>
          <div className={getNavLinkClass(location.pathname, associatedRoutes, true)}>
            <NavLink to={route.url}>
              <i className={`fa-solid ${route.faIcon}`}></i>
              <div className={"nav-item-label"}>{route.display}</div>
            </NavLink>
          </div>
        </>
      }
    </>
  )
}

const getNavLinkClass = (locationPathname, associatedRoutes, isTablet = false) => {
  let className = !isTablet ? "module-nav-item" : "module-nav-item-tablet";
  if (associatedRoutes.some(r => r.url === locationPathname)) className += " module-nav-item-selected";
  return className;
}
