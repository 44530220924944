import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { profilesServices } from "../../repos/apiServices";

import { MasterPageContainer } from "../shared/MasterPageContainer";

import '../shared/ContentArea.css';
import './DashboardPage.css';
import './DashboardWidget.css';


export const DashboardPage = () => {

  //#region States
  const [permissions, entryPermissions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const [salesUpcomingRemindersEnabled, setSalesUpcomingRemindersEnabled] = useState(false);
  const [salesQuotationRequestsEnabled, setQuotationRequestsEnabled] = useState(false);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    // TODO(yemon): Just here to simulate the loading indicator...
    setTimeout(() => {
      setIsLoading(false);
      setIsEmpty(true);
    }, 1200);

    // fetchUserPermissions();
    // fetchDashboardProfile();
  }, []);
  //#endregion

  //#region Utilities
  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _permissions = response.data; //['salesInquiries'];
        entryPermissions(_permissions);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchDashboardProfile = () => {
    setIsLoading(true);
    profilesServices.fetchDashboardProfile(auth.getUserId())
      .then((response) => {
        setupProfileWidgets(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const setupProfileWidgets = (profile) => {
    const salesProfile = profile['sales'];
    let _isEmpty = true;
    if (salesProfile['upcomingReminders'] === true) {
      setSalesUpcomingRemindersEnabled(true);
      _isEmpty = false;
    }
    if (salesProfile['quotationRequests'] === true) {
      setQuotationRequestsEnabled(true);
      _isEmpty = false;
    }
    setIsEmpty(_isEmpty);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container dashboard-container">
        {isLoading && <DashboardLoadingIndicator />}
        {isEmpty && <EmptyDashboardIndicator />}
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


export const DashboardLoadingIndicator = () => {
  return (
    <div className={"background-area"}>
      <div className={"dashboard-empty"}>
        <div className={"dashboard-empty-icon"}>
          <i className={"fa-solid fa-circle-notch fa-spin"}></i>
        </div>
        <div className={"dashboard-empty-message"}>
          Loading your Dashboard widgets...
        </div>
      </div>
    </div>
  )
}


export const EmptyDashboardIndicator = () => {
  //#region States
  //#endregion

  //#region Render
  return (
    <div className={"background-area"}>
      <div className={"dashboard-empty"}>
        <div className={"dashboard-empty-icon"}>
          <i className={"fa-solid fa-gauge-high"}></i>
        </div>
        <div className={"dashboard-empty-message"}>
          You have no Dashboard widgets configured.
        </div>
      </div>
    </div>
  )
  //#endregion
}
