import React from "react";
import { Navigation } from "./Navigation";
import { Footer } from "./Footer";

export const MasterPageContainer = ({children}) => {
  //#region Render
  return (
    <div id={"master-container"}>
      <Navigation />

      {children}

      <Footer />
    </div>
  )
  //#endregion
}
