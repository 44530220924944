import React from "react";
import { useAuth } from "../auth/AuthProvider";

import { EMPTY_GUID } from "../../repos/constants";

const getStyle = (fade) => {
  if (fade) {
    return {
      color: 'gray',
      fontStyle: 'italic',
    }
  }
  else {
    return {};
  }
}

export const ActorNameDisplay = ({ employee, forceFade = false }) => {
  const auth = useAuth();

  return (
    <>
      {!employee && <span style={getStyle(true)}>(NA)</span>}
      {employee && employee.id === auth.getUserId().eid &&
        <span style={getStyle(false)}><i>You</i></span>
      }
      {employee && employee.id !== auth.getUserId().eid &&
        <span style={getStyle(forceFade || employee.id === EMPTY_GUID)}>{employee.name}</span>
      }
    </>
  )
}

export const ActorNameRankDisplay = ({ employee, forceFade = false }) => {
  const auth = useAuth();

  return (
    <>
      {!employee && <span style={getStyle(true)}>(NA)</span>}
      {employee && employee.id === auth.getUserId().eid &&
        <span style={getStyle(false)}>
          You,
          {employee.id !== EMPTY_GUID && <><br />{employee.rank}</>}
        </span>
      }
      {employee && employee.id !== auth.getUserId().eid &&
        <span style={getStyle(forceFade || employee.id === EMPTY_GUID)}>
          {employee.name},
          {employee.id !== EMPTY_GUID && <><br />{employee.rank}</>}
        </span>
      }
    </>
  )
}
